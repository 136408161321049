import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormInput } from '../../../components/form/FormInput';
import Backend from '../../../stores/newBackend';
import { SelectAutocomplete } from '../../../components/form/FormAutocomplete';

import classes from './EditKeywordModal.module.css';

type Inputs = {
  brand: string;
  localization: string;
  keyword: string;
  averageCtr: string;
  averageCpc: string;
  cpcCampaignIds: string[];
  negativeList: string[];
}

interface EditKeywordModalPros {
  id: string;
  onClose: () => void;
}

export function EditKeywordModal({
  id, onClose
}: EditKeywordModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const loadBrands = (_inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve([
          { value: 'brand1', label: 'Brand1' },
          { value: 'brand2', label: 'Brand2' },
          { value: 'brand3', label: 'brand3' },
          { value: 'brand4', label: 'Brand4' }
        ]);
      }, 500);
    });
  };

  const loadLocalizations = (_inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve([
          { value: 'loc1', label: 'Localization1' },
          { value: 'loc2', label: 'Localization2' },
          { value: 'loc3', label: 'Localization3' },
          { value: 'loc4', label: 'Localization4' }
        ]);
      }, 500);
    });
  };

  const loadKeywords = (_inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve([
          { value: 'k1', label: 'Keyword1' },
          { value: 'k2', label: 'Keyword2' },
          { value: 'k3', label: 'Keyword3' },
          { value: 'k4', label: 'Keyword4' }
        ]);
      }, 500);
    });
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    // TODO: connect api
    try {
      await Backend.updateStopGoKeyword(id, data);
      onClose();
      toast(t('Saved keyword.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t save keyword.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Edit keyword')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectAutocomplete
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            loadItems={loadBrands}
          />
          <SelectAutocomplete
            name="localization"
            label={t('Localization')}
            control={control}
            disabled={submitting}
            loadItems={loadLocalizations}
          />
          <SelectAutocomplete
            name="keyword"
            label={t('Keyword')}
            control={control}
            disabled={submitting}
            loadItems={loadKeywords}
          />
          <div className={classes.twoFieldsRow}>
            <FormInput
              name="averageCtr"
              label={t('Average CTR')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormInput
              name="averageCpc"
              label={t('Average CPC')}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          {/*<SelectInput
            name="cpcCampaignIds"
            label={t('CPC Campaign IDs')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />
          <SelectInput
            name="negativeList"
            label={t('Negative list (default account)')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />*/}
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt={t('Cancel')} />
            <SubmitButton
              isLoading={submitting}
              txt={t('Save keyword')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
