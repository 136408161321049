import { useState, useEffect, useContext } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useForm, SubmitHandler } from 'react-hook-form';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import Backend from '../../../stores/newBackend';
import { StoreContext } from '../../../stores';
import type { BrandInterface } from '../../../types/brand.interface';
import type { CustomerInterface } from '../../../types/google.interface';
import { SelectInput } from '../../../components/form/FormSelect';

import classes from './AddGoogleConnectModal.module.css';
import { TagsInput } from '../../../components/form/FormTags';

type Inputs = {
  brand: { label: string, value: string };
  domains: {
    label: string;
    value: string;
  }[],
  googleCustomerId: {
    label: string,
    value: string,
  }
}

interface AddGoogleConnectModalPros {
  onClose: (success: boolean) => void;
}

export function AddGoogleConnectModal({
  onClose
}: AddGoogleConnectModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
  } = useForm<Inputs>();

  const selectedCustomerID = watch('googleCustomerId');
  const [submitting, setSubmitting] = useState(false);
  const { authStore } = useContext(StoreContext);

  const [brands, setBrands] = useState<BrandInterface[]>([]);
  const [customers, setCustomers] = useState<CustomerInterface[]>([]);

  useEffect(() => {
    setBrands([]);
    setCustomers([]);

    const run = async () => {
      const brandsPromise = Backend.loadBrand(authStore.token);
      // const brandsPromise = Promise.resolve([]);
      const customersPromise = Backend.loadGoogleCustomerAvailable(authStore.token);
      // const customersPromise = Promise.resolve([]);

      const [brands, customers] = await Promise.all([brandsPromise, customersPromise]);
      setBrands(brands);
      setCustomers(customers);
    };

    run();
  }, [authStore.token]);

  const selectedCustomer = customers.find((c) => c.ID === selectedCustomerID.value);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!selectedCustomer) {
      return;
    }

    const domains: string[] = [];
    if (data.domains) {
      data.domains.forEach((d) => {
        domains.push(d.value);
      });
    }

    setSubmitting(true);
    try {
      await Backend.googleAddCustomer(
        authStore.token,
        selectedCustomer.ID,
        selectedCustomer.fromID,
        selectedCustomer.loginCustomer ? selectedCustomer.loginCustomer.ID : undefined,
        data.brand.value,
        domains[0],
      );
    } catch (e) {
      console.error(e);
      toast.error(i18next.t('An error occured').toString());
    }
    setSubmitting(false);
    onClose(true);
  };

  return (
    <FullscreenModal
      title={t('Link brand to a Google Customer ID')}
      onClose={() => onClose(false)}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            options={[...(brands || []).map(b => ({ value: b.ID, label: b.label }))]}
          />
          <SelectInput
            name="googleCustomerId"
            label={t('Google Customer ID (Available)')}
            placeholder={t('Link the brand with a Google Customer ID account')}
            control={control}
            disabled={submitting}
            options={[...(customers || []).map(c => ({ value: c.ID, label: `${c.name} (${c.ID})` }))]}
          />
          <TagsInput
            name="domains"
            label={t('Analytic domain')}
            placeholder={t('Define domain and press ENTER')}
            disabled={submitting}
            control={control}
          />
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt={t('Cancel')} />
            <SubmitButton
              isLoading={submitting}
              txt={t('Save brand')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
