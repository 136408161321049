import _ from 'lodash';
import PropTypes from 'prop-types';

import GoogleAnalyticsEnabled from './google-analytics.png';
import GoogleAnalyticsDisabled from './google-analytics-gris.png';
import GoogleSearchEnabled from './google-search-console.png';
import GoogleSearchDisabled from './google-search-console-gris.png';
import GoogleAdsEnabled from './logo-google-ads.png';
import GoogleAdsDisabled from './logo-google-ads-gris.png';

import classes from './ScopeCell.module.css';

ScopeCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  displayTime: PropTypes.bool,
  onClick: PropTypes.func
};

const POSSIBLE_SCOPES = ['google-analytics', 'search-console', 'google-ads',];
const IMAGES = {
  'google-analytics': {
    enabled: GoogleAnalyticsEnabled,
    disabled: GoogleAnalyticsDisabled
  },
  'google-ads': {
    enabled: GoogleSearchEnabled,
    disabled: GoogleSearchDisabled
  },
  'search-console': {
    enabled: GoogleAdsEnabled,
    disabled: GoogleAdsDisabled
  }
};

function extractScopes(value) {
  return (value || []).map(v => {
    if (v.includes('https://www.googleapis.com/auth/adwords')) {
      return 'google-ads';
    }
    if (v.includes('https://www.googleapis.com/auth/webmasters')) {
      return 'search-console';
    }
    return v;
  });
}

export default function ScopeCell({ item, field, onClick }) {
  const value = _.get(item, field);
  const scopes = extractScopes(value);
  return (
    <>
      {POSSIBLE_SCOPES.map(possibleScope => scopes.includes(possibleScope) ? (
        <span
          onClick={() => onClick && onClick(item, possibleScope)}
        >
          <img src={IMAGES[possibleScope].enabled} alt='scope enable' className={classes.scopeIcon} />
        </span>
      ) : (
        <span
          onClick={() => onClick && onClick(item, possibleScope)}
        >
          <img src={IMAGES[possibleScope].disabled} alt='scope disable' className={classes.scopeIcon} />
        </span>
      ))}
    </>
  );
}
