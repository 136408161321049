import classes from './UserForm.module.css';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import AvatarEditor from '../../AvatarEditor/AvatarEditor';
import FormInputTxt from '../../Form/FormInputTxt/FormInputTxt';
import { useTranslation } from 'react-i18next';
import SubmitButton from '../../SubmitButton/SubmitButton';
import CancelButton from '../../CancelButton/CancelButton';
import CompanyAccountSelector from '../../CompanyAccountSelector/CompanyAccountSelector';
import RolesSelector from '../../../RolesSelector/RolesSelector';
import { useContext, useReducer } from 'react';
import {
  UserFormActionsType,
  UserFormInitialState,
  UserFormReducer,
} from './UserForm.reducer';
import { toast } from 'react-toastify';
import { UserAccountApi } from '../../../swagger_client/src';
import superagent from 'superagent';
import generator from 'generate-password';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import getAvatar from '../../../Utils/getAvatar';
import { StoreContext } from '../../../stores';
import { observer } from 'mobx-react-lite';
import Button from '../../Button/Button';

UserForm.propTypes = {
  fields: PropTypes.object,
};

function UserForm({ fields }) {
  const
    { authStore } = useContext(StoreContext),
    userAccountApi = new UserAccountApi(),
    history = useHistory(),
    { t } = useTranslation(),
    { register, handleSubmit, formState: { errors } } = useForm({
      mode: 'onChange',
      reValidateMode: 'onSubmit',
      defaultValues: fields
        ? { ...fields }
        : {
          firstName: '',
          lastName: '',
          avatar: '',
        },
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    }),
    [{ isLoading, companyAccount, roles, avatar }, dispatch] = useReducer(
      UserFormReducer,
      UserFormInitialState
    );

  async function onSubmit(formValues) {
    dispatch({ type: UserFormActionsType.setIsLoading, payload: true });

    const user = {
      ...formValues,
      roles,
      dashboards: {},
    };

    if (companyAccount) {
      user.companyAccountId = companyAccount.id;
    }
    if (formValues.clicdataUrl) {
      user.dashboards['app-action-sem-summary'] = formValues.clicdataUrl;
    }

    if (formValues.clicdataLogin && formValues.clicdataPassword) {
      user.dashboards.clicdata = {
        login: formValues.clicdataLogin,
        password: formValues.clicdataPassword,
      };
    }

    if (avatar) {
      try {
        const { body } = await superagent
          .post(process.env.REACT_APP_MONIBRAND_BACKEND_API_URL + '/Files/upload')
          .set('Authorization', authStore.token)
          .attach('file', avatar);
        user.avatarId = body.id;
      } catch (e) {
        toast.error(t('there are an error with avatar upload'));
      }
    }

    if (fields) {
      try {
        await userAccountApi.userAccountPrototypePatchAttributes(fields.id, {
          body: user,
        });
        return history.push('/users');
      } catch (e) {
        toast.error(t(e));
      }
    } else {
      user.password = generator.generate({
        length: 20,
        numbers: true,
      });

      try {
        await userAccountApi.userAccountCreate({
          body: {
            ...user,
          },
        });
        return history.push('/users');
      } catch (e) {
        toast.error(t(e));
      }
    }

    dispatch({ type: UserFormActionsType.setIsLoading, payload: false });
  }

  return (
    <div className={classes.container}>
      <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
        <div className="avatar">
          <AvatarEditor
            currentAvatar={
              _.get(fields, 'avatarId') ? getAvatar(fields.avatarId) : false
            }
            onSubmit={(payload) =>
              dispatch({ type: UserFormActionsType.setAvatar, payload })
            }
          />
        </div>
        <FormInputTxt
          label="email"
          name="email"
          id="email"
          type="email"
          errors={errors}
          register={register('email', {
            required: { value: true },
            pattern: /[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+/gm,
          })}
        />

        <div className={classes.containerTwoFields}>
          <FormInputTxt
            label="first name"
            name="firstName"
            id="firstName"
            register={register('firstName')}
          />
          <FormInputTxt label="last name" name="lastName" id="lastName" register={register('lastName')} />
        </div>

        {fields &&
          <div className={classes.containerButtons}>
            <Button
              text="reset password"
              onClick={() => userAccountApi.userAccountResetPassword({ email: fields.email })}
            />
          </div>
        }

        <div className={classes.field}>
          <label className={classes.label}>{t('Roles')}</label>
          <div className={classes.input}>
            <RolesSelector
              defaultValue={_.get(fields, 'roles', [])}
              onChange={(selected) =>
                dispatch({
                  type: UserFormActionsType.setRoles,
                  payload: selected,
                })
              }
            />
          </div>
        </div>
        <div className={classes.field}>
          <label className={classes.label}>{t('Company account')}</label>
          <div className={classes.input}>
            <CompanyAccountSelector
              isMulti={false}
              defaultValue={_.get(fields, 'companyAccount')}
              onChange={(selected) =>
                dispatch({
                  type: UserFormActionsType.setCompanyAccount,
                  payload: selected,
                })
              }
            />
          </div>
        </div>
        <div className={classes.separate} />
        <FormInputTxt
          label={t('dashboard ClicData')}
          name="clicdataUrl"
          id="clicdataUrl"
          errors={errors}
          placeholder="url"
          register={register('clicdataUrl')}
        />
        <div className={classes.containerTwoFields}>
          <FormInputTxt
            label={t('login ClicData')}
            name="clicdataLogin"
            id="clicdataLogin"
            register={register('clicdataLogin')}
          />
          <FormInputTxt
            label={t('password ClicData')}
            type="password"
            name="clicdataPassword"
            id="clicdataPassword"
            register={register('clicdataPassword')}
          />
        </div>
        <div className={classes.containerTwoFields}>
          <CancelButton txt="cancel" onClose={() => history.push('/users')} />
          <SubmitButton
            txt={fields ? 'update the user' : 'create the user'}
            disabled={isLoading}
            isLoading={isLoading}
          />
        </div>
      </form>
    </div>
  );
}

export default observer(UserForm);
