import { BrandInterface } from './brand.interface';
import { Status } from './action.interface';
import { normalize } from 'path';

export interface History {
  ID: string;
  status: string;
  date: Date;
}

export type GroupedHistory = History & {
  previousDate: Date[];
};

export function isBeforeReappearance(history: History[], index: number) {
  if (index < history.length - 1) {
    return history[index + 1].status === Status.reappearance;
  }
  return false;
}

export function computeHistory(history: History[], full: boolean): GroupedHistory[] {
  const newHistory = [...history];
  const groupedHistory: GroupedHistory[] = [];

  let newArray = newHistory;
  let lastReappearanceItemIndex = 1;
  if (!full && newHistory && newHistory.length > 0) {
    for (let i = 0; i < newHistory.length; i++) {
      if (newHistory[i].status === Status.reappearance) {
        lastReappearanceItemIndex = i;
      }
    }
    const begin = [newHistory[0]];
    const end = newHistory.slice(lastReappearanceItemIndex);
    if (begin[0].status === Status.reappearance) {
      newArray = end;
    } else {
      newArray = begin.concat(end);
    }
  }

  let prevReminded: GroupedHistory | null = null;
  for (const [index, item] of newArray.entries()) {
    let firstReminded: boolean = false;

    if (item.status === Status.reminded) {
      if (!prevReminded) {
        firstReminded = true;
        prevReminded = {
          ...item,
          previousDate: [],
        };
        //continue;
      } else {
        firstReminded = false;
      }
      const prevDates = prevReminded.previousDate
        ? prevReminded.previousDate
        : [];

      const computedPreviousDates = [...prevDates, item.date];

      const newHistory: GroupedHistory = {
        ...prevReminded,
        previousDate: firstReminded ? [] : computedPreviousDates,
      };

      if (
        groupedHistory[groupedHistory.length - 1]?.status !== 'reminded'
      ) {
        groupedHistory[groupedHistory.length] = newHistory;
        prevReminded = newHistory;
        continue;
      } else {
        groupedHistory[groupedHistory.length - 1] = newHistory;
        prevReminded = newHistory;
        continue;
      }
    } else {
      groupedHistory.push({
        ...item,
        previousDate: [],
      });
      prevReminded = null;
    }
  }

  if (
    groupedHistory.length > 0 &&
    groupedHistory[groupedHistory.length - 1].status !== Status.reminded &&
    prevReminded
  ) {
    groupedHistory.push(prevReminded);
  }
  return groupedHistory;
}

export interface Action {
  ID: string;
  domain: string;
  lastView: Date;
  engine: string;
  country: string;
  status: Status;
  metas?: any;
  blockedDate?: Date;
  waiting?: boolean;
  receivedCount: number;
  unreadCount: number;
  sendedCount: number;
  remindedCount: number;
  totalRemindedCount: number;
  history: History[];
  brandId: string;
  brand: BrandInterface;
  advertiserID: string;
  links: {
    self: string;
    markAsRequested?: string;
    markAsIgnored?: string;
    markAsAccepted?: string;
    markAsRefused?: string;
    markAsInprogress?: string;
    markAsBlocked?: string;
    cancelRequested?: string;
    cancelIgnore?: string;
    cancel?: string;
    getNotes: string;
    addNote: string;
    advertiserGetNotes: string;
    advertiserAddNote: string;
    getTags: string;
    brandAdvertiserGetNotes?: string;
    brandAdvertiserAddNote?: string;
    forceReminderCycle?: string;
  };
  createdDate: Date;
}

export function getStatusLabel(status: string, t: (sd: string) => string) {
  switch (status) {
    case Status.new:
      return t('New');
    case Status.reappearance:
      return 'Réapparu';
    case Status.ignored:
      return t('Ignored');
    case Status.accepted:
      return t('Accepted');
    case Status.refused:
      return t('Refused');
    case Status.disappeared:
      return t('No more detected');
    case Status.requested:
      return t('Requested');
    case Status.inprogress:
      return t('Notified');
    case Status.reminded:
      return t('Reminded');
    case Status.blocked:
      return t('Blocked');
    default:
      return null;
  }
}
