import { action, makeObservable, observable } from 'mobx';
import PageStore from '../../../stores/page.store';
import Backend from '../../../stores/newBackend';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';
import { ISO3, convertISO3ToISO2 } from '../../../Utils/i18n/locale';

export default class GoogleConnectItemDataStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItems: any[] = [];
  selectedItem = null;
  tableHeader = [
    {
      name: 'selectAll',
      value: 0,
      handleClick: () => this.toggleSelectAll(),
      center: true,
    },
    {
      name: 'date',
      title: 'Date',
      sortable: true,
      center: true,
    },
    {
      name: 'term',
      title: 'Keywords',
      sortable: true,
      center: true,
    },
    {
      name: 'country',
      title: 'Country',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    {
      name: 'impressionSEO',
      title: 'Impression SEO',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    {
      name: 'impressionSEA',
      title: 'Impression SEA',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    {
      name: 'clickSEO',
      title: 'Click SEO',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    {
      name: 'clickSEA',
      title: 'Click SEA',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
  ];
  direction = 'desc';
  order = 'Keywords';
  token = '';
  customerID = '';

  constructor(userId: string, token: string, customerID: string) {
    super(userId);
    makeObservable(this, {
      selectedItems: observable,
      selectedItem: observable,
      toggleSelectAll: action,
      toggleSelectOne: action,
      setSelectedItem: action,
    });

    this.token = token;
    this.customerID = customerID;
    this.defaultFilters = {};
    this.filters = { ...this.defaultFilters };
  }

  toggleSelectAll() {
    console.log('>> toggleSelectAll - ', this.items);
    console.log('>> toggleSelectAll - check = ', this.items.length === this.selectedItems.length);
    if (this.items.length === this.selectedItems.length) {
      this.selectedItems = [];
      console.log('>> toggleSelectAll - selectedItems (1) = ', this.selectedItems);
      this.tableHeader[0].value = 0;
      this.setSelectedItem(null);
    } else {
      this.selectedItems = this.items.map((item) => item.ID);
      this.setSelectedItem(this.items[0]);
      console.log('>> toggleSelectAll - selectedItems (2) = ', this.selectedItems);
      this.tableHeader[0].value = 1;
    }
  }

  toggleSelectOne(item: any) {
    console.log('>> toggleSelectOne - ', this.items);
    this.selectedItems = _.xor([item.ID], this.selectedItems);
    console.log('>> toggleSelectOne - selectedItems = ', this.selectedItems);
    this.setSelectedItem(this.selectedItems.length === 1 ? item : null);
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.selectedItems = [];
    this.setSelectedItem(null);
    this.setIsLoading(true);
    this.isLoadingCount = true;

    try {
      let result = await Backend.loadGoogleConnectItemStats(this.token, this.customerID);
      // For testing only
      /* result = [
        {
          ID: '1',
          "date": "2024-12-26T08:00:00.000Z",
          "term": "camif armoires",
          "country": "FRA",
          "SEO": {
              "clicks": 0,
              "impressions": 8
          },
          "SEA": {
              "clicks": 0,
              "impressions": 0
          }
        }
      ]; */
      this.setItems(result.map((r) => ({
        ...r,
        keywords: r.term,
        country: r.country ? convertISO3ToISO2(r.country as ISO3) : '',
        impressionSEO: r.SEO.impressions.toString(),
        impressionSEA: r.SEA.impressions.toString(),
        clickSEO: r.SEO.clicks.toString(),
        clickSEA: r.SEA.clicks.toString()
      })));
      this.setCount(result.length);
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.setIsLoading(false);
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'item', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }
}
