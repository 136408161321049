import { useState } from 'react';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BrandApi } from '../../../swagger_client/src';
import FormInputTxt from '../../Form/FormInputTxt/FormInputTxt';
import FullscreenModal from '../../FullscreenModal/FullscreenModal';
import classes from './BrandForm.module.css';
import CancelButton from '../../CancelButton/CancelButton';
import SubmitButton from '../../SubmitButton/SubmitButton';
import { toast } from 'react-toastify';
import { observer } from 'mobx-react-lite';
import PropTypes from 'prop-types';
import SelectCompanyAccount from '../SelectCompanyAccount/SelectCompanyAccount';

BrandForm.propTypes = {
  fields: PropTypes.object,
  update: PropTypes.func,
  setClose: PropTypes.func,
  getAutoMatchConfig: PropTypes.func
};

function BrandForm({ fields, setClose, update, getAutoMatchConfig }) {
  const { t } = useTranslation(),
    [isLoading, setIsLoading] = useState(),
    { register, handleSubmit, formState: { errors }, control } = useForm({
      mode: 'onChange',
      reValidateMode: 'onSubmit',
      defaultValues: fields ? {
        ...fields,
        autoMatchRegExp: getAutoMatchValue(fields.autoMatchRegExp),
        strictAutoMatch: getStrictAutoMatchValue(fields.autoMatchRegExp),
        autoMatchExcludeRegExp: getAutoMatchValue(fields.autoMatchExcludeRegExp),
        strictAutoMatchExclude: getStrictAutoMatchValue(fields.autoMatchExcludeRegExp),
      } : {},
      criteriaMode: 'firstError',
      shouldFocusError: true,
      shouldUnregister: true,
    });

  async function handleSubmitForm(formValues) {

    const brandApi = new BrandApi(),
      body = {
        ...formValues,
        autoMatchRegExp: setAutoMatch(formValues.autoMatchRegExp, formValues.strictAutoMatch),
        autoMatchExcludeRegExp: setAutoMatch(formValues.autoMatchExcludeRegExp, formValues.strictAutoMatchExclude),
      },
      selectedCompanyAccounts = _.get(formValues, 'companyAccounts', []).map(o => _.get(o, 'value', o)),
      fieldCompanyAccounts = _.chain(fields).get('companyAccounts', []).map(o => o.value).value();
    //DON'T PASS company accounts on patch attributes.
    delete body.companyAccounts;

    setIsLoading(true);

    try {
      if (fields) {
        update(
          await brandApi.brandPrototypePatchAttributes(fields.id, { body })
        );
        toast(t('brand updated'));
      } else {
        body.name = _.kebabCase(formValues.label);
        const newBrand = await brandApi.brandCreate({ body });
        formValues.id = newBrand.id;
        if (selectedCompanyAccounts.length > 0) {
          newBrand.companyAccounts = [...selectedCompanyAccounts];
        }

        update(newBrand);
        toast(t('brand created'));
      }
      setClose();
    } catch (e) {
      toast.error(t(e));
    }

    // if return object (not array)
    if (formValues?.companyAccounts?.id) {
      formValues.companyAccounts = [...formValues.companyAccounts];
    }

    if (fields) {
      //Unlink existing company account
      for (const CompanyAccountToDelete of fieldCompanyAccounts) {
        try {

          await brandApi.brandPrototypeUnlinkCompanyAccounts(fields.id, CompanyAccountToDelete.id, {});
        } catch (e) {
          toast.error(t(e));
        }
      }
    }

    //Link form's company account
    for (const companyAccountToAdd of selectedCompanyAccounts) {
      try {
        await brandApi.brandPrototypeLinkCompanyAccounts(fields ? fields.id : formValues.id, companyAccountToAdd.id, {});
      } catch (e) {
        toast.error(t(e));
      }
    }

    setIsLoading(false);
  }

  function getAutoMatchValue(field) {
    return field ? getAutoMatchConfig(field).rawAutoMatch : '';
  }

  function getStrictAutoMatchValue(autoMatchField) {
    if (autoMatchField) {
      return isStrictAutoMatch(autoMatchField);
    }
    return true;
  }

  function isStrictAutoMatch(automatch) {
    const autoMatchResult = getAutoMatchConfig(automatch);
    return autoMatchResult.fullAutoMatch !== autoMatchResult.rawAutoMatch;
  }

  function setAutoMatch(automatch, strictAutoMatch) {
    if (strictAutoMatch && automatch) {
      return `\\b${automatch}\\b`;
    }
    return automatch;
  }

  return (
    <FullscreenModal onClose={setClose} maxWidth={'800px'}>
      <form className={classes.form} onSubmit={handleSubmit(handleSubmitForm)}>
        <div className={classes.fullSizeField}>
          <FormInputTxt
            register={register('label', {
              required: { value: true, message: t('The name is required') },
            })}
            errors={errors}
            label={t('label')}
            id="label"
            name="label"
            placeholder={t('label')}
          />
        </div>
        <div className={classes.input}>
          <FormInputTxt
            register={register('autoMatchRegExp')}
            errors={errors}
            label={t('auto match')}
            name="autoMatchRegExp"
            id="autoMatchRegExp"
            placeholder={t('regex without "/"')}
          />
        </div>
        <div className={classes.fieldAutoMatch}>
          <input
            type="checkbox"
            {...register('strictAutoMatch')}
            name="strictAutoMatch"
            id="strictAutoMatch"
            data-testid="strictAutoMatch"
          />
          <label htmlFor="strictAutoMatch">{t('strictAutoMatch')}</label>
        </div>

        <div className={classes.input}>
          <FormInputTxt
            register={register('autoMatchExcludeRegExp')}
            errors={errors}
            label={t('exclusion auto match')}
            name="autoMatchExcludeRegExp"
            id="autoMatchExcludeRegExp"
            placeholder={t('regex without "/"')}
          />
        </div>
        <div className={classes.fieldAutoMatch}>
          <input
            type="checkbox"
            {...register('strictAutoMatchExclude')}
            name="strictAutoMatchExclude"
            id="strictAutoMatchExclude"
            data-testid="strictAutoMatchExclude"
          />
          <label htmlFor="strictAutoMatchExclude">{t('strictAutoMatchExclude')}</label>
        </div>

        <div className={classes.input}>
          <FormInputTxt
            register={register('reminder.frequency')}
            type="number"
            errors={errors}
            label={t('Days before relaunch')}
            name="reminder.frequency"
            id="reminder.frequency"
            placeholder={t('in day')}
            defaultValue={15}
          />
        </div>
        <div className={classes.input}>
          <FormInputTxt
            register={register('reminder.quota')}
            type="number"
            errors={errors}
            label={t('quota of notification')}
            name="reminder.quota"
            id="reminder.quota"
            defaultValue={3}
          />
        </div>

        <div className={classes.select}>
          <label className={classes.labelSelect} htmlFor="companyAccounts">
            {t('Company accounts')}
          </label>
          <SelectCompanyAccount
            name="companyAccounts"
            defaultValue={_.get(fields, 'companyAccounts', [])}
            control={control}
          />
        </div>

        <div className={classes.field}>
          <input
            type="checkbox"
            {...register('createNotification')}
            name="createNotification"
            id="createNotification"
          />
          <label htmlFor="createNotification">{t('createNotification')}</label>
        </div>
        <div className={classes.field}>
          <input
            type="checkbox"
            {...register('notificationAuto')}
            name="notificationAuto"
            id="notification"
          />
          <label htmlFor="notification">Notification auto</label>
        </div>

        <div className={classes.field}>
          <input
            type="checkbox"
            {...register('reminder.autoReminder')}
            name="reminder.autoReminder"
            id="autoReminder"
          />
          <label htmlFor="autoReminder">Auto reminder</label>
        </div>
        <div className={classes.field}>
          <input
            type="checkbox"
            {...register('validated')}
            name="validated"
            id="validated"
          />
          <label htmlFor="validated">{t('validate the brand')}</label>
        </div>

        <div className={classes.containerButtons}>
          <CancelButton onClose={setClose} txt="cancel" />
          <SubmitButton
            testid="BrandForm-submit"
            isLoading={isLoading}
            txt={fields ? 'update the brand' : 'create the brand'}
          />
        </div>
      </form>
    </FullscreenModal>
  );
}

export default observer(BrandForm);
