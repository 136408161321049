import { makeObservable, observable } from 'mobx';
import PageStore from '../../../stores/page.store';
import Backend from '../../../stores/newBackend';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

export default class StopGoStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItem = null;
  tableHeader = [
    {
      name: 'keywords',
      title: 'Keywords',
      sortable: true,
      center: true,
    },

    {
      name: 'country',
      title: 'Country',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    { name: 'actions', title: '', align: 'right' },
  ];
  direction = 'desc';
  order = 'Keywords';
  token = '';
  keywordId = '';

  constructor(userId: string, token: string, keywordId: string) {
    super(userId);
    makeObservable(this, {
      selectedItem: observable,
    });

    this.token = token;
    this.keywordId = keywordId;
    this.defaultFilters = {};
    this.filters = { ...this.defaultFilters };
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.isLoading = true;
    this.isLoadingCount = true;

    try {
      const result = await Backend.loadGoogleConnect(this.token);
      this.count = result.length;
      this.setItems(result.map(r => ({
        ...r,
        keywords: 'test',
        country: 'France'
      })));
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.isLoading = false;
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'item', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }
}
