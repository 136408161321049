import { ChangeEvent } from 'react';
import { Controller, Control } from 'react-hook-form';

import classes from './FormInput.module.css';

interface InputProps {
  name: string;
  label: string;
  className?: string;
  defaultValue?: string;
  control: Control;
  disabled?: boolean;
}

export const FormInput = ({
  name, label, className, defaultValue, control, disabled
}: InputProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, ref }}) => (
        <div className={`${classes.container} ${className || ''}`}>
          <label
            className={classes.label}
            htmlFor={name}
          >{label}</label>
          <input
            type="text"
            className={classes.input}
            disabled={disabled}
            value={value}
            onChange={(evt: ChangeEvent<HTMLInputElement>) => onChange(evt.target.value)}
          />
        </div>
      )}
    />
  );
};
