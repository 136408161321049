import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ShortMenuAction from '../../../components/_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import ShortMenuList from '../../../components/_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuModale from '../../../components/_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import { Link as LinkIcon, FilePenLine } from 'lucide-react';
import { EditKeywordModal } from '../Modals/EditKeywordModal';
import { Link } from 'react-router-dom';
import classes from './StopGoKeywordsActionsCell.module.css';

interface StopGoKeywordsActionsCellProps {
  field?: string;
  item?: any;
}

export default function StopGoKeywordsActionsCell({
  item
}: StopGoKeywordsActionsCellProps) {
  const { t } = useTranslation();
  const [updateOpened, setUpdateOpened] = useState(false);

  return (
    <div className={classes.container}>
      <Link to={`stop-go-keyword-management/${item.id}`}><FilePenLine className={classes.actionIcon} /></Link>
      <ShortMenuModale>
        <ShortMenuList>
          <ShortMenuAction
            icon={<LinkIcon />}
            iconMode
            text={t('Update options')}
            onClick={() => setUpdateOpened(true)}
          />
        </ShortMenuList>
      </ ShortMenuModale>
      {updateOpened && (
        <EditKeywordModal
          id={item.ID}
          onClose={() => setUpdateOpened(false)}
        />
      )}
    </div>
  );
}
