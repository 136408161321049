import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormInput } from '../../../components/form/FormInput';
import Backend from '../../../stores/newBackend';

import classes from './AddKeywordModal.module.css';
import { IStopGoKeyword } from '../../../swagger_client/src/model/StopGoKeyword';
import { SelectAutocomplete } from '../../../components/form/FormAutocomplete';
import { StoreContext } from '../../../stores';

type Inputs = {
  brand: string;
  localization: string;
  keyword: string;
  averageCtr: string;
  averageCpc: string;
  cpcCampaignIds: string[];
  negativeList: string[];
}

interface AddKeywordModalPros {
  onKeywordAdded: (addedKeyword: IStopGoKeyword) => void;
  onClose: () => void;
}

export function AddKeywordModal({
  onKeywordAdded, onClose
}: AddKeywordModalPros) {
  const { t } = useTranslation();
  const { authStore, uiStore } = useContext(StoreContext);
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const loadBrands = (_inputValue: string) => {
    return Backend.loadStopGoBrand(authStore.token).then(brands => {
      return brands.map(brand => ({
        value: brand.ID,
        label: brand.brandLabel
      }));
    });
  };

  const loadLocalizations = (_inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve([
          { value: 'loc1', label: 'Localization1' },
          { value: 'loc2', label: 'Localization2' },
          { value: 'loc3', label: 'Localization3' },
          { value: 'loc4', label: 'Localization4' }
        ]);
      }, 500);
    });
  };

  const loadKeywords = (_inputValue: string) => {
    return new Promise<any[]>((resolve) => {
      setTimeout(() => {
        resolve([
          { value: 'k1', label: 'Keyword1' },
          { value: 'k2', label: 'Keyword2' },
          { value: 'k3', label: 'Keyword3' },
          { value: 'k4', label: 'Keyword4' }
        ]);
      }, 500);
    });
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    try {
      const addedKeyword = await Backend.addStopGoKeyword(data);
      onKeywordAdded(addedKeyword);
      onClose();
      toast(t('Added keyword.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t add keyword.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Add keyword')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectAutocomplete
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            loadItems={loadBrands}
          />
          <SelectAutocomplete
            name="localization"
            label={t('Localization')}
            control={control}
            disabled={submitting}
            loadItems={loadLocalizations}
          />
          <SelectAutocomplete
            name="keyword"
            label={t('Keyword')}
            control={control}
            disabled={submitting}
            loadItems={loadKeywords}
          />
          <div className={classes.twoFieldsRow}>
            <FormInput
              name="averageCtr"
              label={t('Average CTR')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormInput
              name="averageCpc"
              label={t('Average CPC')}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          {/*<SelectInput
            name="cpcCampaignIds"
            label={t('CPC Campaign IDs')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />
          <SelectInput
            name="negativeList"
            label={t('Negative list (default account)')}
            control={control}
            disabled={submitting}
            options={[{ value: 'lcid1 ', label: 'lC ID 1' }]}
            isMulti
          />*/}
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt={t('Cancel')} />
            <SubmitButton
              isLoading={submitting}
              txt={t('Add keyword')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
