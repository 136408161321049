import { useFlags } from 'flagsmith/react';
import MenuSection from '../MenuSection/MenuSection';
import { routes } from '../../../Router/routes';
import classes from './MenuContent.module.css';
import MenuCategory from '../MenuCategory/MenuCategory';
import { observer } from 'mobx-react';
import DashboardSVG from '../../../components/Icon/svg/Dashboard.svg';
import MonitoringSVG from '../../../components/Icon/svg/Monitoring.svg';
import ParametersSVG from '../../../components/Icon/svg/Parameters.svg';
import DollarSVG from '../../../components/Icon/svg/Dollar.svg';

const shouldDisplayRoute = (
  route: typeof routes[number],
  flags: { [key: string]: any }
) => {
  if (!route.displayMenu) {
    return false;
  }

  if (route.flag && flags[route.flag]) {
    return flags[route.flag].enabled;
  }

  return true;
};

function MenuContent({ setIsOpen }: { setIsOpen: () => void }) {
  const menus = [
    { label: 'home', standalone: true, icon: DashboardSVG },
    { label: 'monitoring', icon: MonitoringSVG },
    { label: 'notifications', standalone: true },
    { label: 'settings', icon: ParametersSVG },
    { label: 'stopAndGo', icon: DollarSVG },
  ];

  const routeFlags: string[] = [];
  routes.forEach((route) => {
    if (!route.flag) {
      return;
    }
    routeFlags.push(route.flag);
  });

  const flags = useFlags(routeFlags);

  return (
    <div className={classes.content}>
      {menus.map((menu) =>
        menu.standalone ? (
          <MenuSection
            key={menu.label}
            setIsOpen={setIsOpen}
            section={menu.label}
            standalone={menu.standalone}
            routes={routes.filter((route) => shouldDisplayRoute(route, flags))}
          />
        ) : (
          <MenuCategory
            key={menu.label}
            section={menu.label}
            title={menu.label}
            icon={menu.icon}
            routes={routes.filter((route) => shouldDisplayRoute(route, flags))}
          >
            <MenuSection
              setIsOpen={setIsOpen}
              section={menu.label}
              standalone={menu.standalone}
              routes={routes.filter((route) =>
                shouldDisplayRoute(route, flags)
              )}
            />
          </MenuCategory>
        )
      )}
    </div>
  );
}
export default observer(MenuContent);
