import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import type { BrandInterface } from '../../../types/brand.interface';
import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { SelectInput } from '../../../components/form/FormSelect';
import Backend from '../../../stores/newBackend';
import { StoreContext } from '../../../stores';

import classes from './AddStopGoBrandModal.module.css';
import { TagsInput } from '../../../components/form/FormTags';

type Inputs = {
  brand: { label: string, value: string };
  domains: {
    label: string;
    value: string;
  }[]
}

interface AddStopGoBrandModalProps {
  onClose: () => void;
}

export function AddStopGoBrandModal({
  onClose
}: AddStopGoBrandModalProps) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);
  const [brands, setBrands] = useState<BrandInterface[]>([]);
  const { authStore } = useContext(StoreContext);

  useEffect(() => {
    setBrands([]);

    const run = async () => {
      const brands = await Backend.loadBrand(authStore.token);
      setBrands(brands);
    };

    run();
  }, [authStore.token]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setSubmitting(true);
    try {
      const brandID = data.brand.value;
      const domains: string[] = [];

      if (data.domains) {
        data.domains.forEach((d) => {
          domains.push(d.value);
        });
      }

      await Backend.linkStopGoBrand(authStore.token, brandID, domains);
      onClose();
      toast(t('Linked brand.'), { type: 'success' });
    } catch (err) {
      toast(t('Can\'t link brand.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Add brand')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <SelectInput
            name="brand"
            label={t('Brand')}
            control={control}
            disabled={submitting}
            options={[...(brands || []).map(b => ({ value: b.ID, label: b.label }))]}
          />
          <TagsInput
            name="domains"
            label={t('Official domains')}
            control={control}
          />
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt={t('Cancel')} />
            <SubmitButton
              isLoading={submitting}
              txt={t('Save brand')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
