import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import { toast } from 'react-toastify';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormDatepicker } from '../../../components/form/FormDatepicker';
import Backend from '../../../stores/newBackend';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';
import { StoreContext } from '../../../stores';

import classes from './RetrieveDataModal.module.css';
import { SelectInput } from '../../../components/form/FormSelect';

type Inputs = {
  startDate: Date;
  endDate: Date;
  keywords: {
    value: string;
    label: string;
  }[];
}

interface RetrieveDataModalPros {
  item: IStopGoGoogleConnect;
  displayKeywords?: boolean;
  onClose: () => void;
}

export function RetrieveDataModal({
  item, displayKeywords, onClose
}: RetrieveDataModalPros) {
  const { t } = useTranslation();
  const { authStore } = useContext(StoreContext);
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);
  const [keywords, setKeywords] = useState<{ label: string; value: string }[]>([]);
  const currentStartDate: any = useWatch({
    control,
    name: 'startDate'
  });

  useEffect(() => {
    Backend.loadGoogleConnectItemKeywords(authStore.token, item.ID).then(result => {
      setKeywords(result.map(keyword => ({ value: keyword.details.id, label: keyword.details.content })));
    });

  }, [displayKeywords]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    // TODO: connect api (missing keywordID ?)
    try {
      if (displayKeywords) {
        await Backend.retrieveStopGoKeywords(
          authStore.token,
          item.customerID,
          data.startDate,
          data.endDate,
          data.keywords.map(keyword => keyword.value)
        );
      } else {
        await Backend.retrieveStopGoKeywords(
          authStore.token,
          item.customerID,
          data.startDate,
          data.endDate
        );
      }
      onClose();
      toast(t('Retrieved data.'), { type: 'success' });
    } catch(err) {
      console.log(err);
      toast(t('Can\'t retrieve data.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Retrieve data')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          {displayKeywords && (
            <SelectInput
              name="keywords"
              label={t('Keywords')}
              control={control}
              disabled={submitting}
              isMulti
              options={keywords}
            />
          )}
          <div className={classes.twoFieldsRow}>
            <FormDatepicker
              name="startDate"
              label={t('Start date (included)')}
              control={control}
              disabled={submitting}
              className={classes.leftField}
            />
            <FormDatepicker
              name="endDate"
              label={t('End date (included)')}
              minDate={currentStartDate}
              control={control}
              disabled={submitting}
              className={classes.rightField}
            />
          </div>
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt={t('Cancel')} />
            <SubmitButton
              isLoading={submitting}
              txt={t('Retrieve')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
