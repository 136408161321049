import { useRef } from 'react';
import PropTypes from 'prop-types';
import classes from './FullscreenModal.module.css';
import CloseIcon from '../_ui/CloseIcon/CloseIcon';

FullscreenModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  maxWidth: PropTypes.string,
  noCross: PropTypes.bool,
  fullHeight: PropTypes.bool
};

export default function FullscreenModal({ children, title, onClose, fullHeight, noCross}) {
  const ref = useRef(null);

  function clickOutside(e) {
    if (ref.current && !ref.current.contains(e.target)) onClose();
    return ref;
  }

  return (
    <div
      className={classes.container}
      onClick={clickOutside}
    >
      <div className={classes.modale} ref={ref} data-height={fullHeight}>
        <div className={classes.modaleHeader}>
          {title && (<h2 className={classes.modaleHeaderTitle}>{title}</h2>)}
          {!noCross && <div className={classes.close}>
            <CloseIcon onClick={onClose} />
          </div>}
        </div>
        {children}
      </div>
    </div>
  );
}
