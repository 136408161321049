import { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import StopGoStore from './StopGo.store';
import Pagination from '../../components/Pagination/Pagination';
import CompanyAccountsFilter from '../../components/Filters/CompanyAccountsFilter/CompanyAccountsFilter';
import NavFilter from '../../components/Filters/NavFilter/NavFilter';
import { StoreContext } from '../../stores';
import MagicTable from '../../components/MagicTable/MagicTable';
import SwitcherCell from '../../components/MagicTable/SwitcherCell/SwitcherCell';
import DateCell from '../../components/MagicTable/DateCell/DateCell';
import StopGoBrandsActionsCell from './Brand/StopGoBrandsActionsCell';
import LinkCell from '../../components/MagicTable/LinkCell/LinkCell';
import classes from './StopGo.module.css';
import { toast } from 'react-toastify';
import { IStopGoBrand } from '../../swagger_client/src/model/StopGoBrand';
import OfficialDomainsCell from './Brand/OfficialDomainsCell';
import { AddStopGoBrandModal } from './Modals/AddStopGoBrandModal';

function StopGo() {
  const [addModalDisplayed, setAddModalDisplayed] = useState(false);
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [stopGoStore] = useState(
      () => new StopGoStore(currentUser?.id || '', authStore.token)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Stop an Go'));

    return () => {
      stopGoStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  const toggleState = async (item: IStopGoBrand) => {
    const result = await stopGoStore.toggleEnable(item, 'state');
    if (result) {
      toast(result.state ? t('Brand enabled') : t('Brand disabled'), { type: 'success' });
    } else {
      toast(t('Can\'t enable brand'), { type: 'error' });
    }
  };

  const onDomainsChange = async (item: IStopGoBrand, domains: string[]) => {
    const result = await stopGoStore.updateDomains(item, 'domains', domains);
    if (result) {
      toast(t('Domains updated'), { type: 'success' });
    } else {
      toast(t('Can\'t update domains'), { type: 'error' });
    }
  };

  return (
    <>
      <div className={classes.pageHeader}>
        <button
          className={classes.add}
          onClick={() => setAddModalDisplayed(true)}
        >{t('Add brand')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
          <NavFilter
            isLoading={stopGoStore.isLoadingCount}
            pageStore={stopGoStore}
            counters={stopGoStore.counters}
          >
            <CompanyAccountsFilter label={t('company account')} name='id' />
          </NavFilter>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable store={stopGoStore}>
              <LinkCell field="brandLabel" link="/stop-go-management/<brandLabel>" />
              <OfficialDomainsCell field="domains" onChange={onDomainsChange} />
              <DateCell field="createdDate" />
              <SwitcherCell
                field="enabled"
                onClick={toggleState}
              />
              <StopGoBrandsActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={stopGoStore.count}
          perPage={stopGoStore.perPage}
          page={stopGoStore.page}
          handleSetPage={(pageNumber) => stopGoStore.setPage(pageNumber)}
        />
      </div>
      {addModalDisplayed && (
        <AddStopGoBrandModal
          onClose={
            () => {
              setAddModalDisplayed(false);
              stopGoStore.handleFetch();
            }
          }
        />
      )}
    </>
  );
}

export default observer(StopGo);
