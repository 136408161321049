import { makeObservable, observable } from 'mobx';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

import PageStore from '../../stores/page.store';
import Backend from '../../stores/newBackend';
import { IStopGoBrand } from '../../swagger_client/src/model/StopGoBrand';

export default class StopGoStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItem: IStopGoBrand | null = null;
  tableHeader = [
    { name: 'brandLabel', title: 'brand', sortable: false, center: true },
    {
      name: 'domains',
      title: 'Official domaines',
      sortable: false,
      center: true,
    },
    {
      name: 'createdDate',
      title: 'creation date',
      sortable: false,
      center: true,
    },
    { name: 'enabled', title: 'activation', sortable: false, center: true },
    { name: 'actions', title: '', center: false },
  ];
  direction = 'desc';
  order = 'brandLabel';
  token = '';

  constructor(userId: string, token: string) {
    super(userId);
    makeObservable(this, {
      selectedItem: observable,
    });

    this.token = token;
    this.defaultFilters = {};
    if (!this.filters) this.filters = { ...this.defaultFilters };
  }

  setSelectedItem(value: IStopGoBrand) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.isLoading = true;
    this.isLoadingCount = true;
    try {
      let results = await Backend.loadStopGoBrand(this.token);
      results = results.map(result => ({
        ...result,
        enabled: result.state === 'enable'
      }));
      this.setItems(results);
      this.setCount(results.length);
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }
    this.isLoading = false;
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'brand', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }

  async toggleEnable(item: IStopGoBrand, field: keyof IStopGoBrand) {
    this.items = this.items.map(it => item.id === it.id ? ({ ...it, [field]: !item[field] }) : it);
    try {
      await Backend.updateStopGoBrandItem(item.id, { [field]: !item[field] });
      return {
        ...item,
        [field]: !item[field]
      };
    } catch(err) {
      // Revert
      this.items = this.items.map(it => item.id === it.id ? ({ ...it, [field]: !item[field] }) : it);
      return null;
    }
  }

  async updateDomains(item: IStopGoBrand, field: keyof IStopGoBrand, domains: string[]) {
    const initialItem = this.items.find(it => item.id === it.id);
    this.items = this.items.map(it => item.id === it.id ? ({ ...it, [field]: domains }) : it);
    try {
      await Backend.updateStopGoBrandItem(item.id, { [field]: domains });
      return {
        ...item,
        [field]: domains
      };
    } catch(err) {
      // Revert
      this.items = this.items.map(it => item.id === it.id ? initialItem : it);
      return null;
    }
  }
}
