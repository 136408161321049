import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import classes from './BrandInputSelect.module.css';
import PropTypes from 'prop-types';
import selectOptions from '../../../proptypes/selectOptions.definition';
import _ from 'lodash';
import { BrandApi } from '../../../swagger_client/src';

BrandInputSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape(selectOptions)),
  control: PropTypes.object.isRequired,
  errors: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  menuPosition: PropTypes.oneOf(['absolute', 'fixed']),
  hidden: PropTypes.bool,
  direction: PropTypes.oneOf(['bottom', 'auto', 'top']),
  defaultValue: PropTypes.object,
  isMulti: PropTypes.bool
};

BrandInputSelect.defaultProps = {
  isMulti: false,
};

export default function BrandInputSelect({
  name,
  label,
  defaultValue,
  direction,
  control,
  errors,
  placeholder,
  isClearable,
  menuPosition,
  hidden,
  isMulti,
}) {
  const { t } = useTranslation(),
    brandApi = new BrandApi();


  async function promiseOptions(inputValue) {
    const filter = { limit: 50, order: 'name asc' };

    if (inputValue) {
      filter.where = { name: { regexp: `/${inputValue}/i` } };
    }
    const items = await brandApi.brandFind({
      filter: JSON.stringify(filter),
    });
    const newOptions = _.map(items, (o) => {
      return {
        label: o.label || o.name,
        value: o,
      };
    });

    //protect to duplicate keys
    const response = _.uniqBy([...newOptions], 'value')
      .filter((o) => o.label.toLowerCase().includes(inputValue))
      .sort((a, b) => a.label.localeCompare(b.label));
    // alphabetic order.

    return [{
      label: t('No brand'),
      value: 'delete',
    }, ...response];

  }

  return (
    <div
      className={classes.container}
    >
      <label className={classes.label} htmlFor={name} aria-label={label}>{label}</label>
      <Controller
        name={name}
        menuPosition={menuPosition || 'absolute'}
        control={control}
        hidden={hidden}
        render={({ field }) => (
          <AsyncSelect
            {...field}
            components={{ Input: (props) => <components.Input {...props} data-testid={name} /> }}
            defaultValue={defaultValue}
            id={name}
            menuPlacement={direction}
            loadOptions={promiseOptions}
            placeholder={t(placeholder)}
            isClearable={isClearable}
            inputId={name}
            isMulti={isMulti}
            styles={taglistStyle}
            defaultOptions
            cacheOptions
          />
        )}
      />
      {errors && errors[name] && (
        <div className={classes.errorMsg}>{t(errors[name].message)}</div>
      )}
    </div>
  );
}
