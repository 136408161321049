import { useContext, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ParseUseFormErrors } from '../../Utils/utils';
import ErrorsMsg from '../../components/ErrorsMsg/ErrorsMsg';
import AuthInput from '../../components/AuthInput/AuthInput';
import PublicSubmitButton from '../../components/PublicSubmitButton/PublicSubmitButton';
import classes from './Login.module.css';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../stores';
import _ from 'lodash';

function Login() {
  const { authStore, uiStore } = useContext(StoreContext),
    location = useLocation(),
    onHandleSubmit = (event) => authStore.login(event),
    { signinInProgress, signinErrors, currentUser } = authStore,
    { register, handleSubmit, formState: { errors } } = useForm(),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Dashboard access'));
    // eslint-disable-next-line
  }, []);

  if (currentUser) {
    return (
      <Redirect to={new URLSearchParams(_.get(location, 'search', '?redirect=/')).get('redirect')} />
    );
  } else {
    return (
      <form className={classes.form} onSubmit={handleSubmit(onHandleSubmit)}>
        <AuthInput
          label="Mail"
          id="email"
          name="email"
          type="email"
          register={register('email', {
            required: { value: true, message: t('The email is required') },
          })}
        />
        <AuthInput
          label={t('Mot de passe')}
          id="password"
          name="password"
          type="password"
          register={register('password', {
            required: { value: true, message: t('The password is required') },
          })}
          errors={errors}
        />

        {(signinErrors ||
          (errors && Object.keys(errors).length > 0 &&
            errors.constructor === Object)) && (<ErrorsMsg errors={signinErrors ? signinErrors : ParseUseFormErrors(errors)} UnauthorizedMsg={t('Authentication failed. Pleased check your email / password.')} />)}
        <Link className={classes.link} to="/forgot">
          {t('J\'ai oublié mon mot de passe')}
        </Link>
        <PublicSubmitButton disabled={signinInProgress}>
          {t('Se connecter')}
        </PublicSubmitButton>
      </form>
    );
  }
}

export default observer(Login);
