import { observer } from 'mobx-react-lite';
import { useContext, Children, cloneElement, useMemo, Fragment, useEffect } from 'react';
import { StoreContext } from '../../stores';
import Thead from './Thead/Thead';
import classes from './MagicTable.module.css';
import Inputsearch from '../InputSearch/InputSearch';
import Highlighter from 'react-highlight-words';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import LoaderTable from '../LoaderTable/LoaderTable';
import PropTypes from 'prop-types';
import DynamicScrollbar from '../DynamicScrollbar/DynamicScrollbar';
import OpenNewTabSVG from '../Icon/svg/OpenNewTab';
import NoResult from '../../assets/img/actionNoResult.svg';
import ErrorResult from '../../assets/img/actionError.svg';

Table.propTypes = {
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool,
  noResOrError: PropTypes.bool
};

function Table({ isMobile, children, noResOrError }) {
  return isMobile ? (
    <div>{children}</div>
  ) : (
    <DynamicScrollbar
      className={classes.table}
      contentClassName={noResOrError ? '' : classes.contentTable}
      asContent="table"
      handleHover={false}
    >
      {children}
    </DynamicScrollbar>
  );
}

function getValue(value, tr) {
  if (!value) {
    return '';
  }
  if (_.isArray(value) && tr.field) {
    return value.map((o) => _.get(o, tr.field)).join(', ');
  } else {
    return value.toString();
  }
}

MagicTableRow.propTypes = {
  store: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
  children: PropTypes.node,
  header: PropTypes.array.isRequired,
  checkedField: PropTypes.string,
  clickOnRow: PropTypes.bool,
  onOpen: PropTypes.func
};

function MagicTableRow({
  children, store, item, header,
  checkedField, clickOnRow, onOpen
}) {
  const id = _.get(item, checkedField || 'id');
  // console.log('magictable', id);
  const isRowChecked = useMemo(() => !!store.selectedItems?.find(
    (it) => it === id),
  // eslint-disable-next-line
    [item, checkedField,store.selectedItems]);
  const rowModifier = useMemo(() => item.modifier, [item]);

  return (
    <tr
      className={`${classes.tr} ${isRowChecked ? classes.isRowChecked : ''} ${clickOnRow ? classes.clickOnRow : ''} ${rowModifier === 'warning' ? classes.warning : ''}`}
      data-testid={'tr-' + id}
      onClick={() => clickOnRow && onOpen?.(item)}
    >
      {header.map((h) => (
        <Fragment key={h.name}>
          <td
            key={_.get(item, '0') + id}
            className={`${classes.td} ${h.center ? classes.alignCenter : ''} ${h.align === 'right' ? classes.alignRight : ''} ${h.align === 'left' ? classes.alignLeft : ''}`}
            style={{
              maxWidth: h.maxWidth ? h.maxWidth : 'undefined'
            }}
          >
            {h.name === 'openNewTab' ? (
              <div className={classes.rowTrigger}>
                <OpenNewTabSVG
                  height="100%"
                  color="var(--color-link-menu)"
                  onClick={() => onOpen?.(item)}
                />
              </div>
            ) : Children.toArray(children).find(
              (o) => o.props.field === h.name
            ) ? (
                Children.map(children, (child) =>
                  cloneElement(child, { item, store, ...child.props })
                ).filter((o) => o.props.field === h.name)
              ) : (
                <Highlighter
                  highlightClassName="YourHighlightClass"
                  searchWords={[store.inputFilterValue]}
                  autoEscape={true}
                  textToHighlight={getValue(
                    _.get(item, h.name, ''),
                    h
                  )}
                />
              )}
          </td>
        </Fragment>
      ))}
    </tr>
  );
}

MagicTable.propTypes = {
  store: PropTypes.object.isRequired,
  children: PropTypes.node,
  exportLink: PropTypes.string,
  exportName: PropTypes.string,
  checkedField: PropTypes.string,
  disableFirstFetch: PropTypes.bool,
  showInputSearch: PropTypes.bool,
  noFixedHeader: PropTypes.bool,
  simpleHeader: PropTypes.bool,
  onOpenRow: PropTypes.func,
  clickOnRow: PropTypes.bool
};

function MagicTable({
  children,
  store,
  exportLink,
  exportName,
  checkedField,
  disableFirstFetch,
  showInputSearch = true,
  noFixedHeader,
  simpleHeader,
  onOpenRow,
  clickOnRow
}) {
  const { uiStore: isMobile, authStore } = useContext(StoreContext),
    { currentUser } = authStore,
    { t } = useTranslation(),
    header = useMemo(() => store.tableHeader, [store.tableHeader]),
    items = useMemo(() => store.items, [store.items]),
    { order, direction, errorTimeout, errorOther } = store;

  const noResOrError = !store.isLoading && items && items.length === 0 && !store.errorTimeout ? true : false;

  useEffect(() => {
    if (disableFirstFetch) {
      return;
    }
    store.firstFetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(errorTimeout){
      store.setErrorTimeout(errorTimeout);
    }
    // eslint-disable-next-line
  }, [store.errorTimeout]);

  useEffect(() => {
    if(errorOther){
      store.setErrorTimeout(errorOther);
    }
    // eslint-disable-next-line
  }, [store.errorOther]);

  useEffect(() => {
    if(currentUser.id  && (currentUser.id !== store.userId)){
      store.setUserId(currentUser.id);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const unicityField = checkedField ? checkedField : 'id';


  return (
    <div className={classes.container}>
      { showInputSearch && (
        <Inputsearch
          setter={(payload) => store.setInputFilterValue(payload)}
          exportLink={exportLink}
          exportName={exportName}
        />
      ) }
      <div className={classes.tableContainer}>
        <Table noResOrError={noResOrError} isMobile={!!isMobile.device.match(/mobile/)}>
          {isMobile.device.match(/laptop|desktop/) && (
            <Thead
              header={header}
              sortSetter={(order, direction) =>
                store.setOrder(order, direction)
              }
              order={order}
              direction={direction}
              noFixedHeader={noFixedHeader}
              simpleHeader={simpleHeader}
            />
          )}

          <tbody>
            {store.isLoading && items && items.length === 0 && (
              <LoaderTable cols={header.length} />
            )}
            {!store.isLoading && items && items.length === 0 && !store.errorTimeout && !store.errorOther && (
              <tr className={classes.tr}>
                <td colSpan={header.length} className={classes.noResult} data-testid="no-result-magic-table">
                  <img alt='no result' src={NoResult} />
                  <div className={classes.noResultTitle}>
                    Aucun résultat trouvé
                  </div>
                  <div className={classes.noResultText}>
                    Aucun résultat ne correspond à la recherche que vous avez faite.
                  </div>
                </td>
              </tr>
            )}
            {!store.isLoading && items && items.length === 0 && !store.errorTimeout && store.errorOther && (
              <tr className={classes.tr}>
                <td colSpan={header.length} className={classes.noResult} data-testid="no-result-magic-table">
                  <img alt='no result' src={ErrorResult} />
                  <div className={classes.noResultTitle}>
                    Une erreur est survenue
                  </div>
                  <div className={classes.noResultText}>
                    Réessayez plus tard, si le problème persiste contactez-nous.
                  </div>
                </td>
              </tr>
            )}
            {store.errorTimeout !== null && items && items.length === 0 &&(
              <tr className={classes.tr}>
                <td colSpan={header.length} className={classes.noResult} data-testid="no-result-magic-table">
                  <img alt='no result' src={ErrorResult} />
                  <div className={classes.noResultTitle}>
                    Une erreur est survenue
                  </div>
                  <div className={classes.noResultText}>
                    {t('We found too many results to display. Refine your search by changing your filters.')}
                  </div>
                </td>
              </tr>
            )}
            {store.filteredItems.map((item) => (
              <Fragment key={item.id}>
                <MagicTableRow
                  item={item}
                  store={store}
                  header={header}
                  checkedField={checkedField}
                  clickOnRow={clickOnRow}
                  onOpen={onOpenRow}>
                  {children}
                </MagicTableRow>
                {store.portal && _.get(store.portal, unicityField) === _.get(item, unicityField) && (
                  <tr key={item.id + 'portal'}>
                    <td colSpan={header.length}>
                      {Children.map(children, (child) =>
                        cloneElement(child, { item, store, ...child.props })
                      ).filter((o) => o.props.field === 'portal')}
                    </td>
                  </tr>
                )}
              </Fragment>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default observer(MagicTable);
