import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import FullscreenModal from '../../../components/FullscreenModal/FullscreenModal';
import CancelButton from '../../../components/CancelButton/CancelButton';
import SubmitButton from '../../../components/SubmitButton/SubmitButton';
import { FormInput } from '../../../components/form/FormInput';
import Backend from '../../../stores/newBackend';
import { FormCheckbox } from '../../../components/form/FormCheckbox';
import { IStopGoKeyword } from '../../../swagger_client/src/model/StopGoKeyword';

import classes from './AddSimpleKeywordModal.module.css';

type Inputs = {
  keyword: string;
  country: string;
}

interface AddSimpleKeywordModalPros {
  withSavedGoogle?: boolean;
  onKeywordAdded: (addedKeyword: IStopGoKeyword) => void;
  onClose: () => void;
}

export function AddSimpleKeywordModal({
  withSavedGoogle, onKeywordAdded, onClose
}: AddSimpleKeywordModalPros) {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
  } = useForm<Inputs>();
  const [submitting, setSubmitting] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    console.log(data);
    setSubmitting(true);
    try {
      const addedKeyword = await Backend.addStopGoKeyword(data);
      onKeywordAdded(addedKeyword);
      onClose();
      toast(t('Added keyword.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t add keyword.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <FullscreenModal
      title={t('Add keyword')}
      onClose={onClose}
    >
      <div className={classes.modale}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormInput
            name="keyword"
            label={t('Keyword')}
            control={control}
            disabled={submitting}
          />
          <FormInput
            name="country"
            label={t('Country')}
            control={control}
            disabled={submitting}
          />
          {withSavedGoogle && (
            <FormCheckbox
              name="savedToGoogle"
              label={t('Saved to Google')}
              control={control}
              disabled={submitting}
            />
          )}
          <div className={classes.containerButtons}>
            <CancelButton onClose={onClose} txt="Cancel" />
            <SubmitButton
              isLoading={submitting}
              txt={t('Add keyword')}
              disabled={submitting}
            />
          </div>
        </form>
      </div>
    </FullscreenModal>
  );
}
