import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination/Pagination';
import { StoreContext } from '../../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../../components/MagicTable/MagicTable';
import DateCell from '../../../components/MagicTable/DateCell/DateCell';
import ScopeCell from '../../../components/MagicTable/ScopeCell/ScopeCell';
import { StopGoGoogleConnectItemActionsCell } from './StopGoGoogleConnectItemActionsCell';
import classes from './GoogleConnectData.module.css';
import GoogleConnectItemDataStore from './GoogleConnectItemData.store';
import { RetrieveDataModal } from '../Modals/RetrieveDataModal';
import { PurgeDataModal } from '../Modals/PurgeDataModal';
import CheckboxCell from '../../../components/MagicTable/CheckboxCell/CheckboxCell';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';
import FlagCell from '../../../components/MagicTable/FlagCell/FlagCell';

interface GoogleConnectDataProps {
  googleConnectItem: IStopGoGoogleConnect;
}

function GoogleConnectData({
  googleConnectItem
}: GoogleConnectDataProps) {
  const [retrieveModalDisplayed, setRetrieveModalDisplayed] = useState(false);
  const [purgeModalDisplayed, setPurgeModalDisplayed] = useState(false);
  const { authStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [googleConnectStore] = useState(
      () => new GoogleConnectItemDataStore(currentUser?.id || '', authStore.token, googleConnectItem.ID)
    ),
    { t } = useTranslation();
  const keywordsCount = (googleConnectItem?.keywords?.activated || 0);

  useEffect(() => {
    return () => {
      googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader}>
        <button
          className={classes.purge}
          onClick={() => setPurgeModalDisplayed(true)}
        >{t('Purge datas')}</button>
        <button
          className={classes.retrieve}
          onClick={() => setRetrieveModalDisplayed(true)}
        >{t('Retrieve datas')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable
              store={googleConnectStore}
              checkedField='ID'
            >
              <CheckboxCell checkedField='ID' field="selectAll" />
              <DateCell field="date" />
              <ScopeCell field="scopes" />
              <FlagCell field="country" />
              <StopGoGoogleConnectItemActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        {/*<Pagination
          total={googleConnectStore.count}
          perPage={googleConnectStore.perPage}
          page={googleConnectStore.page}
          handleSetPage={(pageNumber) => googleConnectStore.setPage(pageNumber)}
        />*/}
      </div>
      {retrieveModalDisplayed && (
        <RetrieveDataModal
          item={googleConnectItem}
          displayKeywords
          onClose={() => setRetrieveModalDisplayed(false)}
        />
      )}
      {purgeModalDisplayed && (
        <PurgeDataModal
          item={googleConnectItem}
          keywordsCount={keywordsCount}
          onClose={() => setPurgeModalDisplayed(false)}
        />
      )}
    </div>
  );
}

export default observer(GoogleConnectData);
