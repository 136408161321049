import _ from 'lodash';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';

FlagCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  displayTime: PropTypes.bool
};

export default function FlagCell({ item, field, displayTime }) {
  const countryCode = _.get(item, field);
  return countryCode ? (
    <ReactCountryFlag
      className="emojiFlag"
      countryCode={countryCode}
    />
  ) : null;
}
