import _ from 'lodash';
import PropTypes from 'prop-types';
import { FileWarning } from 'lucide-react';

import classes from './StateCell.module.css';

StateCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
};

export default function StateCell({ item, field}) {
  const value = _.get(item, field);
  if (value === 'warning') {
    return (
      <FileWarning className={classes.stateIcon} />
    );
  }
  return null;
}
