import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';

import classes from './GoogleConnectItem.module.css';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import Loader from '../../components/Loader/Loader';
import { GoogleConnectItemContent } from './GoogleConnect/GoogleConnectItemContent';
import { useParams } from 'react-router-dom';
import Backend from '../../stores/newBackend';
import { IStopGoGoogleConnect } from '../../types/stopgogoogleconnect.interface';

function GoogleConnectItem() {
  const params = useParams() as {[id: string]: string};
  const { authStore, uiStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const googleConnectItemId = params.id;
  const [loading, setLoading] = useState(false);
  const [googleConnectItem, setGoogleConnectItem] = useState<IStopGoGoogleConnect | null>(null);
  const [scrollEnabled] = useState(true);

  useEffect(() => {
    uiStore.setTitle(t('Google connect'));
    setLoading(true);
    Backend.loadGoogleConnectItem(authStore.token, googleConnectItemId).then(googleConnectItem => {
      console.log('>> googleConnectItem = ', googleConnectItem);
      setGoogleConnectItem(googleConnectItem);
      setLoading(false);
    });

    return () => {
      // googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${classes.container} ${loading ? classes.center : ''}`}>
      {loading && <Loader />}
      {loading === false && googleConnectItem && (
        <div
          className={classes.body}
        >
          <GoogleConnectItemContent googleConnectItem={googleConnectItem} />
        </div>
      )}
    </div>
  );
}

export default observer(GoogleConnectItem);
