import { observer } from 'mobx-react-lite';
import { useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../stores';
import { useTranslation } from 'react-i18next';

import classes from './Brand.module.css';
import DynamicScrollbar from '../../components/DynamicScrollbar/DynamicScrollbar';
import Loader from '../../components/Loader/Loader';
import { BrandItemContent } from './Brand/BrandItemContent';
import { useParams } from 'react-router-dom';

function Brand() {
  const params = useParams() as {[id: string]: string};
  const { uiStore } = useContext(StoreContext),
    //const { authStore, uiStore } = useContext(StoreContext),
    /*{ currentUser } = authStore,
    /* [googleConnectStore] = useState(
      () => new GoogleConnectStore(currentUser?.id || '', authStore.token)
    ), */
    { t } = useTranslation();
  const brandId = params.id;
  const loading = false;
  const brand = {};
  const [scrollEnabled] = useState(true);

  useEffect(() => {
    uiStore.setTitle(t('Keyword'));

    return () => {
      // googleConnectStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={`${classes.container} ${loading ? classes.center : ''}`}>
      {loading && <Loader />}
      {loading === false && brand && (
        <>
          {/*GoogleConnectItemHeader
          />*/}
          <DynamicScrollbar
            contentClassName={classes.body}
            noScrollY={!scrollEnabled}
          >
            <BrandItemContent brandId={brandId} />
            { /* <ActionPanels action={action} onModalOpened={onModalOpened} /> */}
          </DynamicScrollbar>
        </>
      )}
    </div>
  );
}

export default observer(Brand);
