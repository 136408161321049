import { useState } from 'react';
import { Trash2 } from 'lucide-react';
import StopGoStore from '../StopGo.store';
import classes from './StopGoGoogleConnectItemActionsCell.module.css';
import { DeleteRowModal } from '../Modals/DeleteRowModal';

interface Props {
  readonly store?: StopGoStore
  readonly item?: any
  readonly field?: string
}

export function StopGoGoogleConnectItemActionsCell({ item, store } : Props) {
  const [deleteOpened, setDeleteOpened] = useState(false);

  return (
    <>
      <button
        className="minimal"
        onClick={() => setDeleteOpened(true)}
      >
        <Trash2 className={classes.actionIcon} />
      </button>
      {deleteOpened && (
        <DeleteRowModal
          id={item.ID}
          type="keyword"
          onClose={() => setDeleteOpened(false)}
        />
      )}
    </>
  );
}
