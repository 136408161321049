import { makeObservable, observable } from 'mobx';
import PageStore from '../../../stores/page.store';
import Backend from '../../../stores/newBackend';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import _ from 'lodash';

export default class StopGoStore extends PageStore {
  endpoint = 'justforabstract';
  selectedItem = null;
  tableHeader = [
    {
      name: 'modifier',
      title: '',
      sortable: false,
      center: false,
    },
    {
      name: 'customerID',
      title: 'Google ads customer',
      sortable: true,
      center: true,
    },

    {
      name: 'loginCustomerID',
      title: 'login Customer ID',
      sortable: true,
      center: true,
      maxWidth: '600px'
    },
    {
      name: 'brandLabel',
      title: 'brand',
      sortable: true,
      center: true,
    },
    {
      name: 'expiration',
      title: 'Expiration',
      sortable: true,
      center: true,
    },
    {
      name: 'scope',
      title: 'Scopes',
      sortable: true,
      center: true,
    },
    {
      name: 'keywords',
      title: 'Keywords',
      sortable: false,
      center: true,
    },
    {
      name: 'createdDate',
      title: 'creation date',
      sortable: true,
      center: true,
    },
    { name: 'actions', title: '', center: true },
  ];
  direction = 'desc';
  order = 'name';
  token = '';

  constructor(userId: string, token: string) {
    super(userId);
    makeObservable(this, {
      selectedItem: observable,
    });

    this.token = token;
    this.defaultFilters = {};
    this.filters = { ...this.defaultFilters };
  }

  setSelectedItem(value: any) {
    this.selectedItem = value;
  }
  async handleCount() { }

  async handleFetch() {
    this.isLoading = true;
    this.isLoadingCount = true;

    try {
      const result = await Backend.loadGoogleConnect(this.token);
      this.count = result.length;
      this.setItems(result.map(res => ({
        ...res,
        modifier: (res.scope || []).length === 0 ? 'warning' : ''}
      )));
    } catch (e: any) {
      toast.error(i18next.t(e.message.toString()) as string);
    }

    this.isLoading = false;
    this.isLoadingCount = false;
  }

  get counters() {
    return [{ txt: 'google connect', count: this.count }];
  }

  filterSearch(item: any) {
    const { name } = item;
    let count = 0;

    if (this.inputFilterValue === '') {
      return true;
    }

    if (name) {
      name.toLowerCase().includes(this.inputFilterValue) && count++;
    }

    return count > 0 ? true : false;
  }

  async toggleScope(item: any, scope: string) {
    let newValue = item.scope;
    if ((item.scope || []).includes(scope)) {
      newValue = item.scope.filter((s: string) => s !== scope);
    } else {
      newValue = item.scope.concat([scope]);
    }
    this.items = this.items.map(it => item.id === it.id ? ({ ...it, scopes: newValue }) : it);
    try {
      await Backend.updateStopGoGoogleConnectItem(this.token, item.id, { scope: newValue });
      return {
        ...item,
        scopes: newValue
      };
    } catch(err) {
      // Revert
      this.items = this.items.map(it => item.id === it.id ? ({ ...it, scope: newValue }) : it);
      return null;
    }
  }
}
