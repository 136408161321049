import GoogleConnectPanel from '../Utils/GoogleConnectPanel';
import classes from './KeywordItemContent.module.css';
import KeywordKeywords from './KeywordKeywords';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../stores';
import { useContext, useEffect, useState } from 'react';
import KeywordItemStore from './KeywordItem.store';
import { KeywordDetails } from './KeywordDetails';

interface KeywordItemContentProps {
  keywordId: string;
}

export const KeywordItemContent = ({
  keywordId
}: KeywordItemContentProps) => {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [keywordItemStore] = useState(
      () => new KeywordItemStore(currentUser?.id || '', authStore.token, keywordId)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect item'));

    return () => {
      keywordItemStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <GoogleConnectPanel>
          <KeywordDetails />
        </GoogleConnectPanel>
        <GoogleConnectPanel managedScroll={false}>
          { /* <KeywordKeywords keywordItemStore={keywordItemStore} /> */ }
        </GoogleConnectPanel>
      </div>
    </div>
  );
};
