import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import HeaderImage from './alert.png';
import Backend from '../../../stores/newBackend';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';
import { StoreContext } from '../../../stores';

interface PurgeDataModalProps {
  item: IStopGoGoogleConnect;
  keywordsCount: number;
  onClose: () => void;
}

export function PurgeDataModal({
  item, keywordsCount, onClose
}: PurgeDataModalProps) {
  const { authStore } = useContext(StoreContext);
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);

  const handlePurge = async function() {
    setSubmitting(true);
    // TODO: connect api (no start and end dates)
    try {
      await Backend.purgeStopGoBrand(
        authStore.token,
        item.customerID,
        // data.startDate,
        // data.endDate
      );
      onClose();
      toast(t('Purged data.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t purge data.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ConfirmModal
      headerImage={<img src={HeaderImage} alt='alert header' height={100} />}
      title={''}
      message={(
        <>
          <p>{t('You\'re about to purge data of ') + keywordsCount + ' ' + t('keywords')}</p>
          <p>{t('Are you sure ?')}</p>
        </>
      )}
      setReset={onClose}
      setSubmit={handlePurge}
      isLoading={submitting}
    />
  );
}
