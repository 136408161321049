import classes from './AuthInput.module.css';
import PropTypes from 'prop-types';

AuthInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

AuthInput.defaultProps = {
  type:'text',
};

export default function AuthInput({
  id,
  name,
  value,
  label,
  placeholder,
  errors,
  onChange,
  disabled,
  type,
  register
}) {
  return (
    <div
      className={`${classes.container}
        ${errors && errors[name] ? classes.error : ''}`}
    >
      <label className={classes.label} htmlFor={name}>
        {label}
      </label>

      <input
        className={classes.input}
        id={id}
        name={name}
        value={value}
        type={type}
        onChange={onChange}
        data-testid={name}
        placeholder={placeholder}
        {...register}
        autoComplete="nope"
        disabled={disabled}
      />
    </div>
  );
}