import { useTranslation } from 'react-i18next';
import { DetailsItem } from '../Utils/DetailsItem';

export const BrandDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <DetailsItem
        label={t('Brand')}
        value="brand"
      />
      <DetailsItem
        label={t('Official domains')}
        value="monibrand.com"
      />
      <DetailsItem
        label={t('Creation date')}
        value="03/09/2024"
      />
      <DetailsItem
        label={t('Enabled')}
        value="no"
      />
    </>
  );
};
