import { useTranslation } from 'react-i18next';
import { DetailsItem } from '../Utils/DetailsItem';
import ReactCountryFlag from 'react-country-flag';

export const KeywordDetails = () => {
  const { t } = useTranslation();

  return (
    <>
      <DetailsItem
        label={t('Brand')}
        value="brand"
      />
      <DetailsItem
        label={t('Keyword')}
        value="keyword"
      />
      <DetailsItem
        label={t('Location')}
        value={
          <ReactCountryFlag
            className="emojiFlag"
            countryCode="us"
          />
        }
      />
      <DetailsItem
        label={t('Average ctr')}
        value="-"
      />
      <DetailsItem
        label={t('Average cpc')}
        value="-"
      />
      <DetailsItem
        label={t('Creation date')}
        value="03/09/2024"
      />
      <DetailsItem
        label={t('Scenarios')}
        value="-"
      />
      <DetailsItem
        label={t('Excluded')}
        value="no"
      />
      <DetailsItem
        label={t('Enabled')}
        value="no"
      />
    </>
  );
};
