import ChipsInput from '../../ChipsInput/ChipsInput';
import classes from './CreateFormTerm.module.css';
import { enginesOptions } from '../../../Utils/rawData/rawData';
import { taglistStyle } from '../../../Utils/reactSelectStyles';
import CompanyAccountSelector from '../../CompanyAccountSelector/CompanyAccountSelector';
import { Controller } from 'react-hook-form';
import FormInputSelect from '../../Form/FormInputSelect/FormInputSelect';
import LoaderSmall from '../../_ui/LoaderSmall/LoaderSmall';
import EngineSelector from '../../EngineSelector/EngineSelector';
import NewTooltip from '../../_ui/Tooltip/NewTooltip';
import PercentInput from '../../_ui/PercentInput/PercentInput';
import AsyncSelect from 'react-select/async';
import CheckboxWithLabel from '../../_ui/CheckboxWithLabel/CheckboxWithLabel';
import DynamicScrollbar from '../../DynamicScrollbar/DynamicScrollbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

interface props {
  readonly control: any;
  readonly register: any;
  readonly getValues: any;
  readonly watch: Function;
  readonly t: Function;
  readonly tagLoading: boolean;
  readonly tags: Array<any>;
  readonly localizations: any;
  readonly terms: Array<string>;
  readonly everyOptions: Array<{ label: string; value: number }>;
  readonly delayOptions: Array<{ label: string; value: string }>;
  readonly regionOptions: Array<{ label: string; value: string }>;
  readonly saveOrganicResults: boolean;
}

export default function CreateFormTermView({
  control,
  getValues,
  register,
  watch,
  t,
  tags,
  tagLoading,
  localizations,
  terms,
  everyOptions,
  delayOptions,
  regionOptions,
  saveOrganicResults,
}: props) {
  return (
    <div className={classes.container}>
      <DynamicScrollbar contentClassName={classes.box}>
        <div className={classes.keywords}>
          <div className={classes.label}>
            {t('Keywords')}
            <NewTooltip
              content={
                <>
                  {t('tooltipCreateFormKeyword_firstline')}
                  <div />
                  {t('tooltipCreateFormKeyword_secondline')}
                  <div />
                  {t('tooltipCreateFormKeyword_thirdline')}
                </>
              }
              placement='right'
            >
              <FontAwesomeIcon icon={faInfoCircle} className={classes.info} />
            </NewTooltip>
          </div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <ChipsInput
                values={value}
                setValues={onChange}
                placeholder={t('Add keyword and press ENTER')}
              />
            )}
            control={control}
            rules={register}
            name='terms'
          />
        </div>
        <div className={classes.field}>
          <div className={classes.label}>{t('Company')}</div>
          <Controller
            render={({ field }) => {
              return <CompanyAccountSelector {...field} menuPlacement='bottom' />;
            }}
            control={control}
            rules={register}
            name='canBeAccessBy'
          />
        </div>
        <div className={classes.field}>
          <div className={classes.label}>{t('Tags')}</div>
          {tagLoading ? (
            <LoaderSmall />
          ) : (
            <FormInputSelect
              values={tags}
              placeholder={t('select tags')}
              menuPosition='absolute'
              direction='bottom'
              isMulti
              name='categories'
              control={control}
            />
          )}
        </div>
        <div className={classes.separator} />
        <div className={classes.field}>
          <div className={classes.label}>{t('Search engines')}</div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <EngineSelector
                value={value}
                onChange={onChange}
                engines={enginesOptions}
              />
            )}
            control={control}
            rules={register}
            name='engine'
          />
        </div>
        <div className={classes.field}></div>
        <div className={classes.field}>
          <div className={classes.label}>{t('Location')}</div>
          <Controller
            render={({ field }) => {
              return <AsyncSelect
                {...field}
                cacheOptions
                styles={taglistStyle}
                defaultOptions
                placeholder={t('Select localization')}
                loadOptions={localizations}
              />;
            }}
            control={control}
            rules={register}
            name='localization'
          />
        </div>
        <div className={classes.field}>
          {regionOptions.length > 0 && (
            <>
              <div className={classes.label}>{t('Targeted location')}</div>
              <FormInputSelect
                key={getValues().localization}
                values={regionOptions}
                defaultValue={regionOptions[0]}
                placeholder={t('Select targeted location')}
                menuPosition='absolute'
                direction='top'
                name='region'
                control={control}
              />
            </>
          )}
        </div>

        <div className={classes.field}>
          <div className={classes.label}>
            {t('Monitoring frequency')}
            {/* <Tooltip
              direction="right"
              content={
                <>
                  {t('tooltipCreateFormKeywordDailyRequests_firstline')}
                  <div />
                  {t('tooltipCreateFormKeywordDailyRequests_secondline')}
                  <div />
                  {t('tooltipCreateFormKeywordDailyRequests_thirdline')}
                  <div />
                  {t('tooltipCreateFormKeywordDailyRequests_quatorline')}
                </>
              }
            /> */}
            <NewTooltip
              content={
                <>
                  {t('tooltipCreateFormKeyword_firstline')}
                  <div />
                  {t('tooltipCreateFormKeyword_secondline')}
                  <div />
                  {t('tooltipCreateFormKeyword_thirdline')}
                </>
              }
              placement='right'
            >
              <FontAwesomeIcon icon={faInfoCircle} className={classes.info} />
            </NewTooltip>
          </div>
          <FormInputSelect
            values={everyOptions}
            placeholder={t('Select frequency')}
            menuPosition='absolute'
            direction='top'
            name='every'
            control={control}
          />
        </div>

        <div className={classes.field}>
          <div className={classes.label} />
          <Controller
            render={({ field: { onChange, value } }) => (
              <PercentInput
                percent={value}
                setPercent={onChange}
                disabled={watch('every')?.value > 0}
              />
            )}
            control={control}
            rules={register}
            name='percent'
          />
        </div>

        <div className={`${classes.fieldCheckbox} ${classes.large}`}>
          <div className={classes.label}>
            {t('In the results page of the engine, you want to collect')}
          </div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CheckboxWithLabel setValue={onChange} value={value}>
                <span className={classes.checkboxText}>
                  {t('the paid results of type ads')}
                </span>
              </CheckboxWithLabel>
            )}
            control={control}
            rules={register}
            name='activeSaveAds'
          />
          <Controller
            render={({ field: { onChange, value } }) => (
              <CheckboxWithLabel setValue={onChange} value={value}>
                <span className={classes.checkboxText}>
                  {t('the paid results of type shopping')}
                </span>
              </CheckboxWithLabel>
            )}
            control={control}
            rules={register}
            name='saveShoppingAds'
          />
          <Controller
            render={({ field: { onChange, value } }) => (
              <CheckboxWithLabel setValue={onChange} value={value}>
                <span className={classes.checkboxText}>
                  {t('the organic results')}
                </span>
              </CheckboxWithLabel>
            )}
            control={control}
            rules={register}
            name='saveOrganicResults'
          />
        </div>
        {saveOrganicResults && (
          <div className={`${classes.fieldCheckbox} ${classes.large}`}>
            <div className={classes.label}>{t('Select delay')}</div>
            <FormInputSelect
              values={delayOptions}
              defaultValue={{
                label: `15 ${t('days')}`,
                value: '15 days',
              }}
              placeholder={t('select delay')}
              menuPosition='absolute'
              direction='top'
              name='delayForOrganicResults'
              control={control}
            />
          </div>
        )}
        <div className={classes.separator} />
        <div className={`${classes.fieldCheckbox} ${classes.large}`}>
          <div className={classes.label}>
            {t('Do you want to enable keyword you just added?', {
              count: terms?.length,
            })}
          </div>
          <Controller
            render={({ field: { onChange, value } }) => (
              <CheckboxWithLabel setValue={onChange} value={value}>
                <span className={classes.checkboxText}>
                  {t('Activate the new keyword', { count: terms?.length })}
                </span>
              </CheckboxWithLabel>
            )}
            control={control}
            rules={register}
            name='state'
          />
        </div>
      </DynamicScrollbar>
    </div>
  );
}
