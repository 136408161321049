import LoaderSmall from '../_ui/LoaderSmall/LoaderSmall';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import classes from './SubmitButton.module.css';

SubmitButton.propTypes = {
  txt: PropTypes.string,
  txtBlock: PropTypes.node,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  testid: PropTypes.string,
};

SubmitButton.defaultProps = {
  isLoading:false,
  disabled: false,
  download: false,
  testid: 'submitButton'
};

export default function SubmitButton({ txt, txtBlock, isLoading, onClick, disabled, download, testid}) {
  const { t } = useTranslation();

  return (
    <button 
      data-testid={testid}
      download={download} 
      type="submit" 
      className={classes.submitButton}
      disabled={disabled || isLoading}  
      onClick={onClick}
      data-loading={isLoading}
    >
      {isLoading ? <LoaderSmall color="white" /> : t(txt) || txtBlock}
    </button>
  );
}