import PropTypes from 'prop-types';
import styles from './CheckList.module.css';
import { useTranslation } from 'react-i18next';

// Importing the validation rules
import {
  UPPERCASE_REGEX,
  LOWERCASE_REGEX,
  NUMBER_REGEX,
  LENGTH_REGEX,
  SPECIAL_CHARS_REGEX
} from '../../Utils/rules/passwordValidation';

const rules = [
  { label: 'One uppercase', pattern: UPPERCASE_REGEX },
  { label: 'One lowercase', pattern: LOWERCASE_REGEX },
  { label: 'One number', pattern: NUMBER_REGEX },
  { label: 'One special char', pattern: SPECIAL_CHARS_REGEX },
  { label: 'Min 10 characters', pattern: LENGTH_REGEX }
];

const CheckList = ({ value, className }) => {
  const { t } = useTranslation();
  return (
    className === 'visible' && (
      <div className={styles.wrapper}>
        {rules.map((rule, index) => {
          const cn =
            value.match(rule.pattern) ? styles.passed : styles.notpassed;
          return <p key={index} className={cn}>{t(rule.label)}</p>;
        })}
      </div>
    )
  );
};

CheckList.propTypes = {
  value: PropTypes.string,
  className: PropTypes.string
};

export default CheckList;
