import CreatableSelect from 'react-select/creatable';
import { Controller, Control } from 'react-hook-form';
import { KeyboardEventHandler, useState } from 'react';

import classes from './FormTags.module.css';

export type SelectInputOption = {
  value: string;
  label: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

interface InnerTagsInputProps {
  name: string;
  label: string;
  className?: string;
  disabled?: boolean;
  inline?: boolean;
  hoverable?: boolean;
  placeholder?: string;
  value: any;
  onChange: (value: any) => void;
}

const InnerTagsInput = ({
  name, label, className, disabled,
  inline, hoverable, placeholder,
  value, onChange
}: InnerTagsInputProps) => {
  const [inputValue, setInputValue] = useState('');
  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
    case 'Enter':
    case 'Tab':
      onChange([...(value || []), createOption(inputValue)]);
      setInputValue('');
      event.preventDefault();
    }
  };

  return (
    <div className={`${inline ? classes.inlineContainer : classes.container} ${className || ''} ${hoverable ? 'hoverable': ''}`}>
      <label
        className={classes.label}
        htmlFor={name}
      >{label}</label>
      <CreatableSelect
        value={value}
        inputValue={inputValue}
        onChange={(value: SelectInputOption) => {
          onChange(Array.isArray(value) ? value.map(v => v.value) : value.value);
        }}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        isClearable
        isMulti
        menuIsOpen={false}
        disabled={disabled}
        placeholder={placeholder}
        styles={{
          control: (baseStyles: any, state: any) => ({
            ...baseStyles,
            border: inline ? 'none' : 'solid 1px #ccc',
            // border: 'none',
            fontSize: '1rem',
            // borderRadius: '10px',
            // paddingTop: '3px',
            // paddingBottom: '3px',
            // To disable the blue border
            // See https://stackoverflow.com/questions/52614304/react-select-remove-focus-border
            boxShadow: 'none',
            textAlign: 'start',
            '&:hover': {
              borderColor: '#ccc',
            },
          }),
          option: (baseStyles: any, state: any) => ({
            ...baseStyles,
            // See https://stackoverflow.com/questions/73939936/react-select-how-to-change-the-font-size-on-on-the-dropdown-menu
            fontSize: '14px',
            textAlign: 'start'
          }),
          multiValueLabel: (baseStyles: any, state: any) => ({
            ...baseStyles,
            fontSize: '1rem',
            color: 'white',
          }),
          multiValue: (baseStyles: any, state: any) => ({
            ...baseStyles,
            padding: 5,
            borderRadius: 5,
            color: 'white !important',
            background: state.data.__isNew__ ?'var(--color-link-menu)': 'var(--color-azure)',
          }),
          indicatorsContainer: () => ({
            display: 'none'
          }),
          dropdownIndicator: () => ({
            display: 'none'
          }),
        }}
      />
    </div>
  );
};

interface TagsInputProps {
  name: string;
  label: string;
  className?: string;
  control: Control;
  disabled?: boolean;
  defaultValue: any;
  inline?: boolean;
  hoverable?: boolean;
  placeholder?: string;
}

export const TagsInput = ({
  name, label, className, defaultValue, inline,
  control, disabled, hoverable, placeholder
}: TagsInputProps) => {
  return (
    <Controller
      control={control}
      defaultValue={defaultValue}
      name={name}
      render={({ field: { onChange, value, ref }}) => {
        return (
          <InnerTagsInput
            name={name}
            label={label}
            className={className}
            disabled={disabled}
            inline={inline}
            hoverable={hoverable}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
          />
        );
      }}
    />
  );
};
