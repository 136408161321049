import { useContext, createContext, ReactNode } from 'react';

type ContextType = {
  brandID: string[] | undefined,
};

const BrandContext = createContext({
  brandID: undefined,
  setBrandID: () => { },
} as ContextType);



export const BrandProvider = ({ children, brandID }: { children: ReactNode, brandID: string[] | undefined }) => {
  console.log('Provider', { brandID });
  return (
    <BrandContext.Provider
      value={{
        brandID,
      }}
    >
      {children}
    </BrandContext.Provider>
  );
};

export const useBrandContext = () => {
  const { brandID } = useContext(BrandContext);
  return { brandID };
};
