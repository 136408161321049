import _ from 'lodash';
import PropTypes from 'prop-types';

KeywordsCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
};

export default function KeywordsCell({ item, field }) {
  const value = _.get(item, field);
  const activated = _.get(value, 'activated');
  const available = _.get(value, 'available');
  return (
    <span>{activated}/{available}</span>
  );
}
