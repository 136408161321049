import { observer } from 'mobx-react-lite';
import GoogleConnectItemStore from './BrandItem.store';
import { useContext, useEffect, useState } from 'react';
import Pagination from '../../../components/Pagination/Pagination';
import { StoreContext } from '../../../stores';
import { useTranslation } from 'react-i18next';
import MagicTable from '../../../components/MagicTable/MagicTable';
import DateCell from '../../../components/MagicTable/DateCell/DateCell';
import ScopeCell from '../../../components/MagicTable/ScopeCell/ScopeCell';
import { StopGoGoogleConnectItemActionsCell } from './StopGoGoogleConnectItemActionsCell';
import classes from './BrandKeywords.module.css';
import { AddSimpleKeywordModal } from '../Modals/AddSimpleKeywordModal';
import SwitcherCell from '../../../components/MagicTable/SwitcherCell/SwitcherCell';
import { toast } from 'react-toastify';

interface BrandKeywordsProps {
  brandItemStore: GoogleConnectItemStore;
}

function BrandKeywords({
  brandItemStore
}: BrandKeywordsProps) {
  const [addModalDisplayed, setAddModalDisplayed] = useState(false);
  const { uiStore } = useContext(StoreContext),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Brand'));

    return () => {
      brandItemStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  const toggleSavedToGoogle = async (item: any) => {
    const result = await brandItemStore.toggleField(item, 'savedToGoogle');
    if (result) {
      toast(result.savedToGoogle ? t('Keyword saved to Google') : t('Keyword unsaved from Google'), { type: 'success' });
    } else {
      toast(t('Can\'t save keyword'), { type: 'error' });
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader}>
        <button
          className={classes.add}
          onClick={() => setAddModalDisplayed(true)}
        >{t('Add keyword')}</button>
      </div>
      <div className='tableContainer'>
        <div className='tableContainerFilter'>
        </div>
        <div className='tableBox'>
          <div className='table'>
            <MagicTable store={brandItemStore}>
              <DateCell field="createdDate" />
              <ScopeCell field="scopes" />
              <SwitcherCell
                field="savedToGoogle"
                onClick={toggleSavedToGoogle}
              />
              <StopGoGoogleConnectItemActionsCell field="actions" />
            </MagicTable>
          </div>
        </div>
        <Pagination
          total={brandItemStore.count}
          perPage={brandItemStore.perPage}
          page={brandItemStore.page}
          handleSetPage={(pageNumber) => brandItemStore.setPage(pageNumber)}
        />
      </div>
      {addModalDisplayed && (
        <AddSimpleKeywordModal
          withSavedGoogle
          onKeywordAdded={(_) => brandItemStore.handleFetch()}
          onClose={() => setAddModalDisplayed(false)}
        />
      )}
    </div>
  );
}

export default observer(BrandKeywords);
