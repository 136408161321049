import { stringify } from 'qs';
import subMonths from 'date-fns/subMonths';
import type { BrandInterface } from '../types/brand.interface';
import type { CustomerInterface } from '../types/google.interface';
import type { IStopGoGoogleConnect } from '../types/stopgogoogleconnect.interface';
import type { IStopGoKeyword } from '../types/stopgokeyword.interface';
import type { IStopGoBrand } from '../types/stopgobrand.interface';
import type { IStopGoGoogleConnectKeywordStat } from '../types/stopgogoogleconnectkeywordstat.interface';
import { TermApi } from '../swagger_client/src';

const BASE_URL = process.env.REACT_APP_MONIBRAND_BACKEND_V2_API_URL as string;

interface SearchActionResponse {
  meta: {
    page: {
      total: number;
      subTotal?: any;
    };
  };
  data: any[];
  links: {
    next?: string;
    prev?: string;
    exportCSV: string;
  };
}

interface KeywordStatResponse {
  meta: {
    page: {
      total: number;
      subTotal?: any;
      totalAds?: number;
      totalViews?: number;
    };
  };
  data: any[];
  links: {
    next?: string;
    prev?: string;
    exportCSV: string;
  };
}

interface AdvertiserStatResponse {
  meta: {
    page: {
      total: number;
      subTotal?: any;
      totalAds?: number;
      totalViews?: number;
    };
  };
  data: any[];
  links: {
    next?: string;
    prev?: string;
    exportCSV: string;
  };
}

interface Brand {
  ID: string;
  name: string;
  label: string;
}

const Backend = {
  async searchTag(token: string, criteria?: any) {
    let endpointURL = `${BASE_URL}/advertiserTag/`;
    if (criteria) {
      const searchParams = stringify(criteria);
      endpointURL += `?${searchParams}`;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async getTagForAction(token: string, actionID: string) {
    let endpointURL = `${BASE_URL}/advertiserTag/action?actionID=${actionID}`;

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response: any = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadAction(token: string, actionID: string) {
    const endpointURL = `${BASE_URL}/actions/detail/${actionID}`;

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAction(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<SearchActionResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/actions/search`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAdvertiserFromAction(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<SearchActionResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/actions/searchAll`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAdvertisements(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<any[]> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/advertisement`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async countAdvertisement(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<{ countAdvertisement: number; countShopping: number }> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/advertisement/count`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async searchAdvertisementsByActionID(
    token: string,
    actionID: string,
    criteria?: any
  ): Promise<any[]> {
    let endpointURL = BASE_URL;
    endpointURL += `/advertisement/action/` + actionID;
    if (criteria) {
      const searchParams = stringify(criteria);
      endpointURL += `?${searchParams}`;
    }
    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async countAdvertisementByActionID(
    token: string,
    actionID?: string
  ): Promise<{ countAdvertisement: number; countShopping: number }> {
    let endpointURL = BASE_URL;
    endpointURL += `/advertisement/action/` + actionID + `/count`;
    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadActionsTodo(
    token: string,
    criteria?: any,
    link?: string
  ): Promise<SearchActionResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/actions/todo`;
      if (criteria) {
        const searchParams = stringify(criteria);
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async markAsRequested(
    token: string,
    actionID: string | string[]
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/actions/requested`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      actionID,
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async markAsInprogress(
    token: string,
    actionID: string | string[],
    comments: string,
    manual: boolean
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/actions/inprogress`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      actionID,
      comments,
      manual,
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async markAsIgnored(
    token: string,
    actionID: string | string[]
  ): Promise<any> {
    const searchParams = stringify({ actionID });
    const link = `/actions/ignored?${searchParams}`;
    return Backend.executeLink(token, link);
  },
  async cancelIgnore(token: string, actionID: string | string[]): Promise<any> {
    const searchParams = stringify({ actionID });
    const link = `/actions/cancelIgnore?${searchParams}`;
    return Backend.executeLink(token, link);
  },
  async cancelRequested(
    token: string,
    actionID: string | string[]
  ): Promise<any> {
    const searchParams = stringify({ actionID });
    const link = `/actions/cancelRequested?${searchParams}`;
    return Backend.executeLink(token, link);
  },
  async fetchLink(token: string, link: string): Promise<any> {
    const endpointURL = `${BASE_URL}${link}`;
    const headers = new Headers();
    headers.set('token', token);

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async executeLink(token: string, link: string, body?: any): Promise<any> {
    const endpointURL = `${BASE_URL}${link}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts: RequestInit = {
      method: 'POST',
      headers,
    };

    if (body !== undefined) {
      opts.body = JSON.stringify(body);
    }

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadKeywordStat(
    token: string,
    criteria?: { [key: string]: unknown },
    link?: string
  ): Promise<KeywordStatResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/keywords/stats`;
      if (criteria) {
        const searchParams = stringify(criteria, {
          serializeDate: (d: Date) => d.toISOString(),
        });
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadAdvertiserStat(
    token: string,
    criteria?: { [key: string]: unknown },
    link?: string
  ): Promise<AdvertiserStatResponse> {
    let endpointURL = BASE_URL;

    if (!link) {
      endpointURL += `/advertisers/stats`;
      if (criteria) {
        const searchParams = stringify(criteria, {
          serializeDate: (d: Date) => d.toISOString(),
        });
        endpointURL += `?${searchParams}`;
      }
    } else {
      endpointURL += link;
    }

    const headers = new Headers();
    headers.set('token', token);
    const opts = {
      method: 'GET',
      headers,
    };
    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadResult(token: string, resultID: string) {
    const endpointURL = `${BASE_URL}/result/${resultID}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadGoogleConnect(token: string): Promise<IStopGoGoogleConnect[]> {
    const endpointURL = `${BASE_URL}/google`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadGoogleConnectItem(token: string, ID: string): Promise<IStopGoGoogleConnect> {
    const endpointURL = `${BASE_URL}/google/${ID}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadBrandKeywords(token: string, ID: string): Promise<any[]> {
    // https://api.preprod.monibrand.com/api/Terms?filter=%7B%22include%22%3A%5B%22brand%22%5D%2C%22limit%22%3A100%2C%22order%22%3A%22term%20asc%22%2C%22where%22%3A%7B%7D%7D
    // const endpointURL = `${BASE_URL}/google/keyword/stats?googleItemId=${ID}`;
    const termApi = new TermApi();
    const filter: { limit: number; order: string; where?: Object, include: Array<string> } = {
      include: ['brand', 'localization'],
      limit: 100,
      order: 'term asc',
      where: {
        brandId: { 'inq': [ID] }
      }
    };
    return termApi.termFind({
      filter: JSON.stringify(filter),
    });
  },
  async loadGoogleConnectItemKeywords(token: string, ID: string): Promise<IStopGoKeyword[]> {
    // const endpointURL = `${BASE_URL}/google/keyword/stats?googleItemId=${ID}`;
    const startDate = subMonths(new Date(), 3);
    const endDate = new Date();
    let query = `customerConfigID=${ID}`;
    const endpointURL = `${BASE_URL}/google/keyword/link?${query}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadGoogleConnectItemStats(token: string, ID: string): Promise<IStopGoGoogleConnectKeywordStat[]> {
    // const endpointURL = `${BASE_URL}/google/keyword/stats?googleItemId=${ID}`;
    const startDate = subMonths(new Date(), 3);
    const endDate = new Date();
    let query = `customerConfigID=${ID}`;
    query += `&startDate=${startDate.toISOString()}`;
    query += `&endDate=${endDate.toISOString()}`;
    const endpointURL = `${BASE_URL}/google/keyword/stats?${query}`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadBrand(token: string): Promise<BrandInterface[]> {
    const endpointURL = `${BASE_URL}/brand`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadGoogleCustomerAvailable(token: string): Promise<CustomerInterface[]> {
    const endpointURL = `${BASE_URL}/google/customer/available`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadStopGoBrand(token: string): Promise<IStopGoBrand[]> {
    const endpointURL = `${BASE_URL}/stop-go/brand`;
    // const endpointURL = `${BASE_URL}/stop-go`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async loadStopGoKeyword(token: string): Promise<IStopGoKeyword[]> {
    const endpointURL = `${BASE_URL}/stop-go/keyword`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');

    const opts = {
      method: 'GET',
      headers,
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async googleAddCustomer(
    token: string,
    ID: string,
    fromID: string,
    loginCustomerID: string | undefined,
    brandID: string,
    domain: string
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/google/customer`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      ID,
      fromID,
      loginCustomerID,
      brandID,
      domain,
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async deleteStopGoBrand(
    keywordId: any
  ): Promise<any> {
    console.log('>> deleteStopGoBrand - keywordId = ', keywordId);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  },
  async retrieveStopGoKeyword(
    filters: any
  ): Promise<any> {
    console.log('>> retrieveStopGoKeyword = ', { filters });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  },
  async retrieveStopGoKeywords(
    token: string,
    customerID: string,
    startDate: Date,
    endDate: Date,
    keywordID?: string[]
  ): Promise<any> {
    let query = `customerConfigID=${customerID}`;
    query += `&startDate=${startDate.toISOString()}`;
    query += `&endDate=${endDate.toISOString()}`;
    const metricsEndpointURL = `${BASE_URL}/google/keyword/metrics/update`;
    const analyticsEndpointURL = `${BASE_URL}/google/keyword/analytics/update`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      customerConfigID: customerID,
      keywordID,
      startDate,
      endDate
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    return Promise.all([
      fetch(metricsEndpointURL, opts).then(response => response.json()),
      fetch(analyticsEndpointURL, opts).then(response => response.json())
    ]);
  },
  async addStopGoKeyword(
    keyword: any
  ): Promise<any> {
    console.log('>> addStopGoKeyword = ', { keyword });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({
          ...keyword,
          id: '123',
        });
      }, 500);
    });
  },
  async deleteStopGoKeyword(
    keywordId: any
  ): Promise<any> {
    console.log('>> deleteStopGoKeyword - keywordId = ', keywordId);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  },
  async linkStopGoBrand(
    token: string,
    brandID: string,
    domains?: string[]
  ): Promise<boolean> {
    const endpointURL = `${BASE_URL}/stop-go/brand`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      brandID,
      domains,
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);

    return response.status === 201;

  },
  async unlinkStopGoKeyword(
    ID: string
  ): Promise<any> {
    console.log('>> unlinkStopGoKeyword = ', { ID });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  },
  async purgeStopGoBrand(
    token: string,
    customerID: string
  ): Promise<any> {
    console.log('>> purgeStopGoBrand = ', {});
    /* return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    }); */
    const endpointURL = `${BASE_URL}/google/keyword/stats `;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      customerConfigID: customerID,
      // keywordID,
      // startDate,
      // endDate
    };

    const opts = {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async mapStopGoKeywords(
    token: string,
    ID: string,
    keywordsIds: string[]
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/google/keyword/link`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      customerConfigID: ID,
      keywordID: keywordsIds[0]
    };

    const opts = {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();
  },
  async unmapStopGoKeywords(
    token: string,
    ID: string,
    keywordsIds: string[]
  ): Promise<any> {
    const endpointURL = `${BASE_URL}/google/keyword/link`;
    const headers = new Headers();
    headers.set('token', token);
    headers.set('Content-Type', 'application/json');
    const body = {
      customerConfigID: ID,
      keywordID: keywordsIds[0]
    };

    const opts = {
      method: 'DELETE',
      headers,
      body: JSON.stringify(body),
    };

    const response = await fetch(endpointURL, opts);
    return response.json();

  },
  updateStopGoGoogleConnectItem(
    token: string,
    ID: string,
    data: any
  ): Promise<any> {
    console.log('>> updateStopGoGoogleConnectItem = ', { ID, data });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 500);
    });
  },
  updateStopGoBrandItem(
    ID: string,
    data: any
  ): Promise<any> {
    console.log('>> updateStopGoBrandItem = ', { ID, data });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(data);
      }, 500);
    });
  },
  updateStopGoKeyword(
    ID: string,
    data: any
  ): Promise<any> {
    console.log('>> updateStopGoKeyword = ', { ID, data });
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  },
  stopGoRenewInvitation(): Promise<any> {
    console.log('>> stopGoRenewInvitation');
    return new Promise(resolve => {
      setTimeout(() => {
        resolve({});
      }, 500);
    });
  }
};

export default Backend;
