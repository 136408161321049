import { useState, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';
import classes from './MenuCategory.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useMenuExpanded } from '../MenuContext';
import { StoreContext } from '../../../stores';

MenuCategory.propTypes = {
  routes: PropTypes.array.isRequired,
  section: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  initState: PropTypes.bool,
  styleTxt: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default function MenuCategory({
  routes,
  section,
  children,
  title,
  initState,
  styleTxt,
  icon,
}) {
  const { t } = useTranslation(),
    location = useLocation(),
    { authStore } = useContext(StoreContext),
    expanded = useMenuExpanded(),
    [toggle, setToogle] = useState(initState === false ? false : true);
  const Icon = icon;

  const matchingRoutes = useMemo(() => {
    return routes
      .filter((route) => route.section === section)
      .filter((route) => !route.isAdmin || authStore.isAdmin);
  }, [routes, section, authStore.isAdmin]);

  const active = useMemo(() => {
    for (const item of matchingRoutes) {
      if (location.pathname === item.path) {
        return true;
      }
    }
    return false;
  }, [location, matchingRoutes]);

  return (
    <div>
      <div
        className={`${classes.title} ${!toggle ? classes.closed : ''} ${!expanded ? classes.collapsed : ''}`}
        data-style={styleTxt}
        onClick={() => setToogle(!toggle)}
        data-open={toggle}
        data-testid='toggle'
      >
        {Icon ? (
          <div className={classes.icon}>
            <Icon
              color={
                active
                  ? 'var(--color-princ-monibrand)'
                  : 'var(--color-link-menu)'
              }
            />
          </div>
        ) : null}
        {expanded && (
          <div className={classes.titleContent}>
            <span className={classes.titleTxt}>{t(title)}</span>
            <FontAwesomeIcon
              className={classes.icon}
              data-down={toggle}
              icon={faChevronUp}
              color={
                active
                  ? 'var(--color-princ-monibrand)'
                  : 'var(--color-link-menu)'
              }
            />
          </div>
        )}
      </div>
      <div>
        <CSSTransition
          in={toggle}
          timeout={200}
          unmountOnExit
          mountOnEnter
          classNames='accordion'
        >
          {children}
        </CSSTransition>
      </div>
    </div>
  );
}
