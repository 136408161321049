import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import HeaderImage from './alert.png';
import Backend from '../../../stores/newBackend';

interface RenewInvitationModalProps {
  onClose: () => void;
}

export function RenewInvitationModal({
  onClose
}: RenewInvitationModalProps) {
  const { t } = useTranslation(),
    [submitting, setSubmitting] = useState(false);

  const handlePurge = async function() {
    setSubmitting(true);
    try {
      await Backend.stopGoRenewInvitation();
      onClose();
      toast(t('Renewed invitation.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t renew invitation.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ConfirmModal
      headerImage={<img src={HeaderImage} alt='alert header' height={100} />}
      title={''}
      message={(
        <>
          <p>{t('You\'re about to renew the invitation.')}</p>
          <p>{t('Are you sure ?')}</p>
        </>
      )}
      setReset={onClose}
      setSubmit={handlePurge}
      isLoading={submitting}
    />
  );
}
