import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import ConfirmModal from '../../../components/ConfirmModal/ConfirmModal';
import HeaderImage from './alert.png';
import Backend from '../../../stores/newBackend';
import { StoreContext } from '../../../stores';

interface DeleteRowModalProps {
  id: string;
  googleConnectId?: string;
  type: string;
  onClose: () => void;
}

export function DeleteRowModal({
  id, googleConnectId, type, onClose
}: DeleteRowModalProps) {
  const { t } = useTranslation();
  const { authStore } = useContext(StoreContext);
  const [submitting, setSubmitting] = useState(false);

  const handlePurge = async function() {
    setSubmitting(true);
    try {
      if (type === 'keyword') {
        await Backend.unmapStopGoKeywords(authStore.token, googleConnectId as string, [id]);
      }
      if (type === 'brand') {
        await Backend.deleteStopGoBrand(id);
      }
      onClose();
      toast(t('Deleted row.'), { type: 'success' });
    } catch(err) {
      toast(t('Can\'t delete row.'), { type: 'error' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <ConfirmModal
      headerImage={<img src={HeaderImage} alt='alert header' height={100} />}
      title={''}
      message={(
        <>
          <p>{t('You\'re about to delete the row.')}</p>
          <p>{t('Are you sure ?')}</p>
        </>
      )}
      setReset={onClose}
      setSubmit={handlePurge}
      isLoading={submitting}
    />
  );
}
