import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ShortMenuAction from '../../../components/_ui/ShortMenu/ShortMenuAction/ShortMenuAction';
import ShortMenuList from '../../../components/_ui/ShortMenu/ShortMenuList/ShortMenuList';
import ShortMenuModale from '../../../components/_ui/ShortMenu/ShortMenuModale/ShortMenuModale';
import StopGoStore from '../StopGo.store';
import { Trash2, MailOpen, ChartNoAxesCombined, FilePenLine } from 'lucide-react';
import { PurgeDataModal } from '../Modals/PurgeDataModal';
import { RetrieveDataModal } from '../Modals/RetrieveDataModal';
import classes from './StopGoGoogleConnectActionsCell.module.css';
import { RenewInvitationModal } from '../Modals/RenewInvitationModal';
import { MapDataNewModal } from '../Modals/MapDataNewModal';
import { IStopGoGoogleConnect } from '../../../types/stopgogoogleconnect.interface';
import { MapDataModal } from '../Modals/MapDataModal';

interface Props {
  readonly store?: StopGoStore
  readonly item?: IStopGoGoogleConnect
  readonly field?: string
}

export default function StopGoGoogleConnectActionsCell({ item, store } : Props) {
  const { t } = useTranslation();
  const [renewOpened, setRenewOpened] = useState(false);
  const [mapOpened, setMapOpened] = useState(false);
  const [retrieveOpened, setRetrieveOpened] = useState(false);
  const [purgeOpened, setPurgeOpened] = useState(false);
  const keywordsCount = /*(item?.keywords?.available || 0) - */(item?.keywords?.activated || 0);
  const notLinkedKeywordsCount = (item?.keywords?.available || 0) - (item?.keywords?.activated || 0);

  const onRenewInvitation = () => {

  };

  return (
    <div className={classes.container}>
      <Link to={`/google-connect/${item?.ID}`}><FilePenLine className={classes.actionIcon} /></Link>
      <ShortMenuModale>
        <ShortMenuList>
          <ShortMenuAction 
            icon={<MailOpen />}
            iconMode
            disabled
            text={t('Renew invitation')}
            onClick={() => setRenewOpened(true)}
          />
          <ShortMenuAction 
            icon={<ChartNoAxesCombined />}
            iconMode
            text={t('Link datas') + (notLinkedKeywordsCount > 0 ? ' (' + notLinkedKeywordsCount + ' ' + t('keywords') + ')' : '')}
            onClick={() => setMapOpened(true)}
          />
          <ShortMenuAction 
            icon={<ChartNoAxesCombined />}
            iconMode
            text={t('Get datas') + (keywordsCount > 0 ? ' (' + keywordsCount + ' ' + t('keywords') + ')' : '')}
            onClick={() => setRetrieveOpened(true)}
          />
          <ShortMenuAction 
            icon={<Trash2 />}
            iconMode
            text={t('Purge datas') + (keywordsCount > 0 ? ' (' + keywordsCount + ' ' + t('keywords') + ')' : '')}
            onClick={() => setPurgeOpened(true)}
          />
        </ShortMenuList>
      </ ShortMenuModale>
      {renewOpened && (
        <RenewInvitationModal
          onClose={() => setRenewOpened(false)}
        />
      )}
      {mapOpened && (
        <MapDataModal
          googleConnectItem={item as IStopGoGoogleConnect}
          onClose={() => setMapOpened(false)}
        />
      )}
      {retrieveOpened && (
        <RetrieveDataModal
          item={item as IStopGoGoogleConnect}
          onClose={() => setRetrieveOpened(false)}
        />
      )}
      {purgeOpened && (
        <PurgeDataModal
          item={item as IStopGoGoogleConnect}
          keywordsCount={keywordsCount}
          onClose={() => setPurgeOpened(false)}
        />
      )}
    </div>
  );
}
