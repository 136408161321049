import _ from 'lodash';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classes from './LinkCell.module.css'; 
import { useMemo } from 'react';

function replaceTokens(template, data) {
  return template.replace(/<([^>]+)>/g, (match, key) => {
    return key in data ? data[key] : match;
  });
}

LinkCell.propTypes = {
  item: PropTypes.object,
  field: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired
};

export default function LinkCell({ item, field, link }) {
  const evaluatedLink = useMemo(() => {
    return replaceTokens(link, item);
  }, [item, link]);
  const value = _.get(item, field);
  return <Link className={classes.linkCell} to={evaluatedLink}>{value}</Link>;
}
