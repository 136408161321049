import { ReactNode } from 'react';
import classes from './DetailsItem.module.css';

interface DetailsItemProps {
  label: string;
  value: string | ReactNode;
}

export const DetailsItem = ({
  label, value
}: DetailsItemProps) => {
  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      <div>{value}</div>
    </div>
  );
};
