import GoogleConnectPanel from '../Utils/GoogleConnectPanel';
import classes from './BrandItemContent.module.css';
import BrandKeywords from './BrandKeywords';
import { useTranslation } from 'react-i18next';
import { StoreContext } from '../../../stores';
import { useContext, useEffect, useState } from 'react';
import BrandItemStore from './BrandItem.store';
import { BrandDetails } from './BrandDetails';

interface BrandItemContentProps {
  brandId: string;
}

export const BrandItemContent = ({
  brandId
}: BrandItemContentProps) => {
  const { authStore, uiStore } = useContext(StoreContext),
    { currentUser } = authStore,
    [brandItemStore] = useState(
      () => new BrandItemStore(currentUser?.id || '', authStore.token, brandId)
    ),
    { t } = useTranslation();

  useEffect(() => {
    uiStore.setTitle(t('Google connect item'));

    return () => {
      brandItemStore.stopStore();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.topContainer}>
        <GoogleConnectPanel>
          <BrandDetails />
        </GoogleConnectPanel>
        <GoogleConnectPanel managedScroll={false}>
          <BrandKeywords brandItemStore={brandItemStore} />
        </GoogleConnectPanel>
      </div>
    </div>
  );
};
